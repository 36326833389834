import { useEffect } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { errorToast } from '../utils';

const ONLINE_STATUS_SUBSCRIPTION = gql`
  subscription messagesSubscription($userId: Int!) {
    user_online(where: { _or: [{ _and: { userOnline: { id: { _eq: $userId } } } }] }) {
      id
      last_seen
    }
  }
`;

function usePatientOnlineStatus(userId: string | undefined) {
  const { error: errorOnlineSubscription, data: { user_online: userOnline } = {} } = useSubscription<{
    user_online: any[];
  }>(ONLINE_STATUS_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: {
      userId,
    },
  });

  // Show error if any
  useEffect(() => {
    if (errorOnlineSubscription) {
      errorToast(errorOnlineSubscription.message);
    }
  }, [errorOnlineSubscription]);

  return userOnline && userOnline?.length > 0;
}

export { usePatientOnlineStatus };
