import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ChevronDoubleRightIcon } from '@heroicons/react/solid';
import Image from '../../../../../components/Image';

interface IPrescriptionItemProps {
  createdAt: string;
  description?: string;
  avatarUrl?: string;
  firstName: string;
  secondName: string;
  prescriptionId: string;
  isBackgroundGray: boolean;
}

const PrescriptionItem = ({
  createdAt,
  description,
  avatarUrl,
  firstName,
  secondName,
  prescriptionId,
  isBackgroundGray,
}: IPrescriptionItemProps) => {
  return (
    <tr className={isBackgroundGray ? 'bg-gray-50' : ''}>
      <td className="whitespace-nowrap px-5 py-4 text-sm text-gray-900">
        {`${format(new Date(createdAt), 'dd-MM-yyyy')}`}
      </td>
      <td className="whitespace-normal max-w-[300px] min-w-[300px] px-3 py-4 text-sm text-gray-900">{description}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm flex items-center text-gray-900">
        <div className="h-10 w-10 flex-shrink-0">
          <Image url={avatarUrl} alt="avatar" className="h-10 w-10 rounded-full bg-slate-600" />
        </div>
        <div className="ml-4">
          <div>{firstName}</div>
          <div>{secondName}</div>
        </div>
      </td>
      <td className="whitespace-nowrap py-4 px-3">
        <Link
          to={`/prescriptions/${prescriptionId}`}
          type="button"
          className="w-full flex justify-center rounded-full border shadow-sm py-2 text-sm font-bold text-violet-600 hover:text-violet-800 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
        >
          <ChevronDoubleRightIcon className="h-5 w-10" />
        </Link>
      </td>
    </tr>
  );
};

PrescriptionItem.defaultProps = {
  avatarUrl: undefined,
  description: '-',
};

export default PrescriptionItem;
