import { ApolloClient, from, fromPromise, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { t } from 'i18next';
import { RefreshTokenDocument } from './generated/graphql';
import { errorToast } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('No API specified');
}

const endpoint1 = createUploadLink({ uri: `${API_URL}graphql/` });

const endpoint2 = new HttpLink({
  // uri: "https://glowing-stingray-38.hasura.app/v1/graphql", // use https for secure endpoint
  uri: `${API_URL}graphql/`, // use https for secure endpoint
  // headers: {
  //   'x-hasura-admin-secret': 'CaTFcmyG7HkQOftb0YLJBfjicD3WZ4S3ziU2VgVWiHn4s4kgmtw8bsac9fJFA8oP',
  // }
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  // uri: "ws://glowing-stingray-38.hasura.app/v1/graphql", // use wss for a secure endpoint
  uri: `${API_URL.replace(/^https?:\/\//, (match) => {
    // Replace http||https with ws||wss
    return match.includes('https') ? 'wss://' : 'ws://';
  })}ws/`,
  options: {
    reconnect: true,
    // connectionParams: {
    //   headers: {
    //     'x-hasura-admin-secret': 'CaTFcmyG7HkQOftb0YLJBfjicD3WZ4S3ziU2VgVWiHn4s4kgmtw8bsac9fJFA8oP'
    //   }
    // }
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  split((operation) => operation.getContext().clientName === 'chat', endpoint2, endpoint1)
);

const asyncAuthLink = setContext(async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  localStorage.getItem('token');
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      Authorization: token ? `Token ${token}` : '',
    },
  };
});

// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (networkError) {
    // TODO: show error message (turn off server)
    errorToast(`${t('Network error')}: ${t(networkError.message)}`);
  }
  if (graphQLErrors) {
    // eslint-disable-next-line no-restricted-syntax
    for (const { message } of graphQLErrors) {
      if (message.includes('Variable "$refreshToken" of required type "String!" was not provided.')) {
        window.location.replace('/');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      } else if (message.includes('Security code is not valid')) {
        errorToast(t('Invalid authorization code.'));
      } else if (message.includes('User with this phone or email already exists')) {
        errorToast(t('User with this phone or email already exists. Perhaps you entered them wrong.'));
      } else if (message.includes('Signature has expired')) {
        return fromPromise(getAndSetNewToken()).flatMap(() => {
          return forward(operation);
        });
      } else if (message.includes('Refresh token is expired')) {
        window.location.replace('/');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      } else {
        errorToast(t(message));
      }
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, asyncAuthLink, link]),
  cache: new InMemoryCache(),
});

function getAndSetNewToken() {
  return client
    .mutate({
      mutation: RefreshTokenDocument,
      variables: { refreshToken: localStorage.getItem('refreshToken') },
    })
    .then(
      ({
        data: {
          refreshToken: { refreshToken, token },
        },
      }) => {
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('token', token);
        return `Token ${token}`;
      }
    )
    .catch((error) => {
      errorToast(t(error.message));
    });
}

export { client };
