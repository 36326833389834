import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { t } from 'i18next';
import { PlusSmIcon as PlusSmIconSolid, XIcon } from '@heroicons/react/outline';

interface Props {
  control: any;
  register: any;
  errors: any;
}

const MedicineIntakeTime = ({ control, register, errors }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'intakeTimes',
  });

  return (
    <div className="border-t border-gray-200 pt-2">
      <label htmlFor="intakeTimes" className="block text-sm font-medium text-gray-700">
        {t('Intake time')}
      </label>
      {fields.map((field, index) => (
        <div className="col-start-2 col-span-2" key={field.id}>
          <div className="mt-1 sm:mt-0 flex">
            <input
              {...register(`intakeTimes[${index}].intakeTime`, { required: `${t('This is required message')}` })}
              type="time"
              name={`intakeTimes[${index}].intakeTime`}
              autoComplete="intakeTimes"
              className={
                errors
                  ? `max-w-lg block w-full self-end  border-red-300 text-red-900 placeholder-red-300 focus:outline-none sm:max-w-xs focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md`
                  : `max-w-lg block w-full self-end shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md`
              }
            />
            {index === 0 && (
              <button
                onClick={() => append({})}
                type="button"
                className="inline-flex items-center mt-2 ml-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-violet-800 hover:bg-violet-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
              >
                <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
            {index > 0 && (
              <button
                onClick={() => remove(index)}
                type="button"
                className="inline-flex items-center mt-2 ml-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MedicineIntakeTime;
