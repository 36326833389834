import React from 'react';

interface IListHeaderProps {
  title: string;
  actionButton?: React.ReactNode;
}

const ListHeader = ({ title, actionButton }: IListHeaderProps) => {
  return (
    <div className="flex justify-between pl-5 items-center px-2 py-3 sm:px-4 text-xl rounded-lg md:rounded-xl bg-gray-200 font-medium text-black">
      <p>{title}</p>
      {actionButton}
    </div>
  );
};

ListHeader.defaultProps = {
  actionButton: undefined,
};

export default ListHeader;
