import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IMedicineFormData } from '@view/widgets/MedicineSlideOverForm';
import { MedicineSlideOverForm, PrescriptionForm } from '../../widgets';
import { usePrescriptionState } from '../../../hooks/usePrescriptionState';
import {
  DoctorCreatePrescriptionInputType,
  PatientDocument,
  useDoctorCreatePrescriptionMutation,
} from '../../../generated/graphql';

const NewPrescriptionPage = () => {
  const [{ listOfMedicines }, { addPrescriptionMedicine }] = usePrescriptionState();
  const [isMedicineFormOpen, setIsMedicineFormOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: paramPatientId } = useParams();

  const [doctorCreatePrescription] = useDoctorCreatePrescriptionMutation({
    onCompleted: () => {
      navigate(-1);
    },
    refetchQueries: [{ query: PatientDocument, variables: { id: paramPatientId } }],
  });

  const { handleSubmit, register } = useForm<DoctorCreatePrescriptionInputType>({
    mode: 'onChange',
  });

  const addMedicineToForm = (medicine: IMedicineFormData) => {
    addPrescriptionMedicine(medicine);
    setIsMedicineFormOpen(false);
  };

  const handleCreatePrescription = async (data: DoctorCreatePrescriptionInputType) => {
    if (listOfMedicines.length > 0 && paramPatientId) {
      await doctorCreatePrescription({
        variables: {
          input: {
            patientId: paramPatientId,
            description: data.description!,
            medicines: listOfMedicines!,
          },
        },
      });
    } else {
      throw new Error("You haven't added any medicine or didn't specify patient");
    }
  };

  return (
    <>
      <MedicineSlideOverForm
        onSubmit={addMedicineToForm}
        onCancel={() => setIsMedicineFormOpen(false)}
        isOpen={isMedicineFormOpen}
      />
      <PrescriptionForm
        onSubmit={handleSubmit(handleCreatePrescription)}
        listOfMedicines={listOfMedicines}
        toggleMedicineSlideOver={() => setIsMedicineFormOpen(true)}
        register={register}
        title={t('New prescription')}
      />
    </>
  );
};

export default NewPrescriptionPage;
