import { useEffect, useRef } from 'react';

const useEffectExceptOnMount = (func: any, deps: any) => {
  const didMount = useRef(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (didMount.current) {
      const unmount = func();
      return () => unmount && unmount();
    }

    didMount.current = true;
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export { useEffectExceptOnMount };
