import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentAddIcon, DocumentTextIcon } from '@heroicons/react/solid';
import {
  PatientDocument,
  useDoctorPatientFileCreateMutation,
  useGeneratePresignedUrlLazyQuery,
} from '../../../../generated/graphql';
import { errorToast, successToast } from '../../../../utils';
import { Button } from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useEffectExceptOnMount } from '../../../../hooks/useEffectExceptOnMount';

interface IUploadPatientFileProps {
  patientId: string;
}

interface FormDefaultValuesType {
  file: File | null;
  title: string;
}

const UploadPatientFile = ({ patientId }: IUploadPatientFileProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const uploadFileInputRef = useRef<HTMLInputElement>(null);
  const [getGeneratePresignedUrl] = useGeneratePresignedUrlLazyQuery();

  const { register, handleSubmit, setValue, watch, getValues, reset, formState } = useForm<FormDefaultValuesType>({
    mode: 'onChange',
    defaultValues: {
      file: null,
      title: '',
    },
  });

  const [createPatientFileMutation, { loading: patientFileMutationLoading }] = useDoctorPatientFileCreateMutation({
    refetchQueries: [PatientDocument],
    onCompleted: () => {
      successToast(t('File successfully uploaded'));
      setIsOpen(false);
    },
    onError: () => {
      errorToast(t('File failed to upload'));
    },
  });

  const handleFileCreate = async (data: FormDefaultValuesType) => {
    if (data.file) {
      const result = await getGeneratePresignedUrl({ variables: { filename: data.file?.name } });

      if (result.data?.getPresignedUrl) {
        await fetch(result.data?.getPresignedUrl, {
          method: 'PUT',
          body: data.file,
        });
        if (data.title) {
          createPatientFileMutation({
            variables: {
              input: {
                file: result.data?.getPresignedUrl,
                title: data.title,
                patientId,
              },
            },
          });
        }
      }
    }
  };

  // Clear file uploading modal onDisappear
  useEffectExceptOnMount(() => {
    if (!isOpen) {
      setTimeout(reset, 400);
    }
  }, [isOpen, reset]);

  const selectedFile = watch('file');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files!;
    if (files.length > 0) {
      const [inputFile] = files;
      const inputTitle = inputFile?.name.replace(/\.\w{2,10}$/, '');
      if (!getValues('title')) {
        setValue('title', inputTitle);
      }
      setValue('file', inputFile);
    }
  };

  return (
    <>
      <button
        disabled={patientFileMutationLoading}
        type="button"
        onClick={() => setIsOpen(true)}
        className="relative block w-full border-2 duration-300 border-violet-300 border-dashed rounded-lg p-6 text-center opacity-75 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <DocumentAddIcon className="w-14 m-auto font-light text-violet-400" />
        <span className="mt-2 block text-sm font-medium text-violet-900">{t('Upload file')}</span>
      </button>
      <Modal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        title={t('Select a file to add')}
      >
        <div className="flex flex-col">
          <input
            {...register('title', {
              required: 'Field is required.',
            })}
            type="text"
            id="title"
            className="my-5 rounded-lg"
          />

          {selectedFile && (
            <div className="flex flex-row justify-center items-center mb-5">
              <span className="flex items-center text-gray-800 text-lg h-12">
                {selectedFile.name.replace(/\.\w{2,10}$/, '')}
              </span>
              <DocumentTextIcon className="h-8 w-8" />
            </div>
          )}

          <div className="flex flex-row space-x-3 md:space-x-10">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              htmlFor="file"
              className="w-full"
              onClick={(e: any) => {
                if (e.target !== e.currentTarget) e.currentTarget.click();
              }}
            >
              <Button className="w-full bg-violet-500 hover:bg-violet-600">
                {!selectedFile ? <span>{t('Select')}</span> : <span>{t('Change')}</span>}
              </Button>
            </label>
            <input
              {...register('file', { required: 'File is required' })}
              type="file"
              id="file"
              name="file"
              className="hidden"
              ref={uploadFileInputRef}
              onChange={handleFileChange}
            />
            <Button
              className="w-full bg-violet-500 hover:bg-violet-600"
              loading={patientFileMutationLoading}
              disabled={!selectedFile || !!formState.errors.title}
              onClick={handleSubmit(handleFileCreate)}
            >
              {t('Upload')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadPatientFile;
