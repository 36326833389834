import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline';
import { Link, useParams } from 'react-router-dom';
import { PrescriptionType } from '../../../../generated/graphql';
import PatientPrescriptionItem from './components/PatientPrescriptionItem';
import ListHeader from '../../../layout/ListHeader';
import ListBody from '../../../layout/ListBody';

interface Props {
  patientPrescriptions: PrescriptionType[];
}

const PatientPrescriptionsList = ({ patientPrescriptions }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <ListHeader
        title={t('Prescriptions')}
        actionButton={
          <Link
            type="button"
            className="self-end inline-flex items-center p-2 border border-transparent border-white rounded-full shadow-sm text-white bg-violet-600 hover:bg-violet-800 focus:outline-none focus:ring-1 focus:ring-offset-1"
            to={`/patients/${id}/new-prescription`}
          >
            <PlusSmIconOutline className="h-6 w-6" aria-hidden="true" />
          </Link>
        }
      />
      <ListBody emptyStateText={t('This patient has no prescriptions yet')}>
        {patientPrescriptions.length > 0
          ? // limit the number of prescriptions displayed with slice
            patientPrescriptions
              .map((prescription) => (
                <PatientPrescriptionItem
                  description={prescription.description}
                  medicines={prescription.prescriptionmedicineSet.edges}
                  id={prescription.id}
                  key={prescription.id}
                />
              ))
              .reverse()
          : null}
      </ListBody>
    </>
  );
};

export default PatientPrescriptionsList;
