class S3BucketService {
  downloadFileFromUrl(url: string, filename = 'EpihelperFile') {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }
}

export const s3BucketService = new S3BucketService();
