import { CloudUploadIcon, TrashIcon } from '@heroicons/react/solid';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/auth-context';
import { DoctorDocument, ImageType, MeDocument, useRemoveUserAvatarMutation } from '../../../generated/graphql';
import DoctorImage from '../DoctorImage';
import ConfirmationModal from '../ConfirmationModal';

interface Props {
  avatar: Partial<ImageType>;
  handleFileChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  localAvatar: File | null;
  localAvatarUrl: string | undefined;
}

const PhotoLoader = ({ avatar, localAvatar, localAvatarUrl, handleFileChange }: Props) => {
  const [value, setValue] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showModal, setShowModal] = useState(false);
  const authContext = useAuth();

  const [removeUserAvatarMutation] = useRemoveUserAvatarMutation({
    refetchQueries: [MeDocument, DoctorDocument],
    onCompleted: (data) => {
      if (data.removeUserAvatar.success) {
        authContext.setUser({ ...authContext.user, avatar: undefined });
      }
    },
  });

  const handleAvatarChangeClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveAvatar = () => {
    if (localAvatar) {
      handleFileChange();
    } else {
      setShowModal(true);
    }
    setValue('');
  };
  const handleChange = (e: any) => {
    handleFileChange(e);
    setValue(e.target.value);
  };

  const { t } = useTranslation();
  return (
    <div className="sm:grid sm:grid-row-3 sm:gap-4 sm:items-start pt-3">
      <label htmlFor="avatar" className="block mt-4 xl:mb-10 text-md xl:text-xl font-medium text-black">
        {t('Avatar')}
      </label>
      <div className="mt-1 sm:mt-0 sm:row-1">
        <div className="flex flex-col items-center">
          <DoctorImage
            url={localAvatarUrl ?? avatar?.url}
            alt="avatar"
            className="h-32 w-32 xl:h-48 xl:w-48 inline-block rounded-full object-cover ring-2 ring-white"
          />
          <input
            ref={fileInputRef}
            type="file"
            name="avatar"
            className="hidden"
            value={value}
            onChange={handleChange}
          />
          <div className="flex flex-row-2 space-x-2">
            <button
              type="button"
              className="flex flex-row-1 mt-3 xl:mt-10 bg-violet-700 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-white hover:bg-violet-500 focus:outline-none focus:ring-violet-500 focus:ring-offset-2"
              onClick={handleAvatarChangeClick}
            >
              <CloudUploadIcon className="mr-2 text-white h-4 w-4" />
              {t('Change')}
            </button>
            {(localAvatarUrl || avatar?.url) && (
              <button
                type="button"
                className="flex flex-row-1 mt-3 xl:mt-10 bg-violet-700 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-white hover:bg-violet-500 focus:outline-none focus:ring-violet-500 focus:ring-offset-2"
                onClick={handleRemoveAvatar}
              >
                <TrashIcon className="mr-2 text-white h-4 w-4" />
                {t('Delete')}
              </button>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={showModal}
        cancelText={t('Cancel')}
        confirmText={t('Delete')}
        title={t('Confirm deletion')}
        confirmButtonType="danger"
        onCancel={() => setShowModal(false)}
        onConfirm={() => {
          removeUserAvatarMutation({
            variables: {
              input: {
                avatarId: avatar.id as string,
              },
            },
          });
          setShowModal(false);
        }}
      >
        {t('Are you sure you want delete avatar image?')}
      </ConfirmationModal>
    </div>
  );
};

export default PhotoLoader;
