import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDoctorTokenAuthMutation, UserRole } from '../../../../generated/graphql';
import { useAuth } from '../../../../contexts/auth-context';
import ResendSMS from '../ResendSMS';

interface CodeDataType {
  code: string;
  isDoctorSigningIn: boolean;
}

interface Props {
  isDoctorSigningIn: boolean;
}

const SignInCodeInput = ({ isDoctorSigningIn }: Props) => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleSubmit, formState, control } = useForm<CodeDataType>({
    mode: 'onChange',
    defaultValues: { code: '' },
  });

  const [tokenAuth] = useDoctorTokenAuthMutation({
    onCompleted: (tokenData) => {
      authContext.login(tokenData);
      if (!isDoctorSigningIn) {
        navigate('/step3');
      } else if (tokenData.tokenAuth.registered && isDoctorSigningIn) {
        navigate('/');
      }
    },
    fetchPolicy: 'no-cache',
  });

  const handleAuthorization = useCallback(
    async ({ code }: CodeDataType) => {
      try {
        await tokenAuth({
          variables: {
            phone: authContext.user!.phone!,
            securityCode: code,
            role: UserRole.A_2,
          },
        });
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [tokenAuth, authContext.user]
  );

  const onEnterPressHandleRegistration = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        handleSubmit(handleAuthorization)();
      }
    },
    [handleSubmit, handleAuthorization]
  );

  return (
    <div className="min-h-full flex flex-col justify-start sm:justify-center py-12 sm:px-6 mx-4 lg:px-4 ">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-6">
          <div className="sm:mx-auto sm:w-full sm:max-w-lg">
            <h2 className="mb-6 text-left text-2xl font-bold text-gray-900">{t('Authorization code sent')}</h2>
          </div>
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {t('Code')}:
              </label>
              <div className="mt-1">
                <Controller
                  render={({ field: { onChange } }) => (
                    <input
                      // disable eslint, becuse autoFocus here can't prichenit vred
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="XXXX"
                      inputMode="decimal"
                      onKeyPress={onEnterPressHandleRegistration}
                      onChange={(e) => onChange(e.target.value)}
                      type="tel"
                      maxLength={4}
                    />
                  )}
                  control={control}
                  name="code"
                  rules={{
                    required: true,
                    pattern: /\d{4}/,
                  }}
                />
              </div>
            </div>
            <div>
              <ResendSMS />
            </div>
            <div>
              <button
                disabled={!formState.isValid}
                onClick={handleSubmit(handleAuthorization)}
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-violet-500 hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
              >
                {t('Next')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInCodeInput;
