import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 lg:col-span-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-violet-600 text-4xl font-extrabold pt-[1.5rem] sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                {t('Page not found')}
              </h1>
              <p className="mt-1 text-base text-gray-500">
                {t('Please check the URL in the address bar and try again.')}
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link
                to="/"
                className="w-full flex items-center cursor-pointer justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-violet-600 hover:bg-violet-800"
              >
                {t('Go on home page')}
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default PageNotFound;
