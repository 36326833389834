import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { PrescriptionMedicineTypeEdge } from '../../../../../../generated/graphql';
import PrescriptionMedicine from '../../../PrescriptionMedicine';
import Card from '../../../../../layout/Card';
import { Button } from '../../../../../components/Button';

interface IPatientPrescriptionItemProps {
  id: string;
  description: string;
  medicines: PrescriptionMedicineTypeEdge[];
}

const PatientPrescriptionItem = ({ id, description, medicines }: IPatientPrescriptionItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card>
      <div className="text-sm text-gray-900">
        <div>
          <div className="flex justify-between">
            <h4 className="text-lg">
              {t('Prescription')}
              {` ${id}`}
            </h4>
            <Button
              onClick={() => {
                navigate(`/prescriptions/${id}`);
              }}
            >
              <div className="hidden sm:block mr-1">{t('Change')}</div>
              <PencilIcon className="h-5 w-5" />
            </Button>
          </div>
          <p className="mt-1">{description && `${t('Description')}: ${description}`}</p>
        </div>
        {medicines.length !== 0 ? <p>{t('Medicines')}:</p> : <p>{t('No medicines added')}</p>}
        {medicines.map((med) => (
          <PrescriptionMedicine key={med.node.id} medicine={med.node} />
        ))}
      </div>
    </Card>
  );
};

export default PatientPrescriptionItem;
