import { useMemo } from 'react';
import { useDoctorPushNotificationsQuery } from '../../generated/graphql';

const useDoctorPushNotificationsQueryProvider = () => {
  const response = useDoctorPushNotificationsQuery({
    fetchPolicy: 'no-cache',
  });

  const doctorNotifications = useMemo(() => {
    return (
      response.data?.doctorPushNotifications.edges.map(({ node: doctorPushNotification }) => ({
        id: doctorPushNotification.id,
        patientFirstName: doctorPushNotification.author.firstName,
        patientSecondName: doctorPushNotification.author.secondName,
        patientImageUrl: doctorPushNotification.author.avatar?.url,
        text: doctorPushNotification.pushNotification.text,
        createdTime: doctorPushNotification.pushNotification.createdAt,
      })) || []
    );
  }, [response.data]);

  return [doctorNotifications, response] as const;
};

export { useDoctorPushNotificationsQueryProvider };
