import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageNotFound from '../../components/PageNotFound';
import NoElementFound from '../../components/NoElementFound';
import Preloader from '../../components/Preloader';
import BreadCrumbs from '../../components/BreadCrumbs';
import PaginationList from '../../components/PaginationList';
import SeizuresTable from '../../widgets/Seizure/SeizuresTable';
import { useDoctorPatientsQuery } from '../../../generated/graphql';

const itemsOnPage = 5;

const SeizuresPage = () => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);

  const pages = [{ name: t('Seizures'), href: '/seizures', current: false }];

  const { data, loading, error } = useDoctorPatientsQuery({
    variables: {
      first: itemsOnPage,
      offset: itemsOnPage * (pageNumber - 1),
    },
  });

  const patients = useMemo(() => {
    return (
      data?.doctorPatients.edges.map((edge) => ({
        id: edge.node.patient.id,
        diagnosis: edge.node.patient.diagnosis,
        epilepsyType: edge.node.patient.epilepsyType,
        firstName: edge.node.patient.user.firstName,
        secondName: edge.node.patient.user.secondName,
        avatar: edge.node.patient.user.avatar?.url,
      })) || []
    );
  }, [data]);

  const totalCount: number | undefined = data?.doctorPatients.totalCount;

  if (loading) {
    return (
      <div className="h-[70vh] col-span-7 items-center flex justify-center">
        <Preloader />
      </div>
    );
  }

  if (patients.length === 0) {
    return <NoElementFound title={t('Patients list is empty')} />;
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return <PageNotFound />;
  }

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">{t('Seizures')}</h1>
      <BreadCrumbs pages={pages} />
      <div className="pt-4">
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-1 md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                <SeizuresTable patients={patients} />
                {totalCount && (
                  <PaginationList
                    itemsOnPage={itemsOnPage}
                    totalCount={totalCount}
                    selectedPage={pageNumber}
                    onPageSelect={setPageNumber}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeizuresPage;
