import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDoubleRightIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import Image from '../../../components/Image';

interface Props {
  patients: any[];
}

const SeizuresTable = ({ patients }: Props) => {
  const { t } = useTranslation();

  const epilepsyType: any = {
    A_1: t('Arbitrary'),
    A_2: t('Post traumatic'),
    A_3: t('Stroke'),
    A_4: t('Cerebral paralysis'),
  };

  return (
    <table className="lg:min-w-full min-w-[900px] divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900">
            {t('Patient')}
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            {t('Diagnosis')}
          </th>
          <th scope="col" className="flex px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            {t('Epilepsy type')}
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4">
            <span className="sr-only">{t('Edit')}</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y items-center divide-gray-200 bg-white">
        {patients.map((patient, index) => (
          <tr key={patient.id} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
            <td className="whitespace-nowrap px-5 py-4 text-sm flex items-center text-gray-900">
              <div className="h-10 w-10 flex-shrink-0">
                <Image url={patient.avatar} alt="avatar" className="h-10 w-10 rounded-full bg-slate-600" />
              </div>
              <div className="ml-4">
                <div>{patient.firstName}</div>
                <div>{patient.secondName}</div>
              </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{patient.diagnosis}</td>
            <td className="whitespace-normal px-3 py-4 text-sm text-gray-900">{epilepsyType[patient.epilepsyType]}</td>
            <td className="whitespace-nowrap py-4 px-3">
              <Link to={`/seizures/patient?id=${patient.id}`}>
                <button
                  type="button"
                  className="w-full rounded-full border flex justify-center shadow-sm py-2 text-sm font-bold text-violet-600 hover:text-violet-800 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
                >
                  <ChevronDoubleRightIcon className="h-5 w-10" />
                </button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SeizuresTable;
