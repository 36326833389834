import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { ToastContainer } from 'react-toastify';
import SignInNumberInput from '../../widgets/SignIn/SignInNumberInput';
import SigninCodeInput from '../../widgets/SignIn/SignInCodeInput';
import UserDataForm from '../../widgets/UserDataForm';

const SignInPage = () => {
  const [isDoctorSigningIn, setIsDoctorSigningIn] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="bg-gray-100 h-screen">
      <ToastContainer />
      <Routes>
        <Route path="*" element={<SignInNumberInput setIsDoctorSigningIn={setIsDoctorSigningIn} />} />
        <Route path="/step2" element={<SigninCodeInput isDoctorSigningIn={isDoctorSigningIn} />} />
        <Route path="/step3" element={<UserDataForm />} />
      </Routes>
    </div>
  );
};

export default SignInPage;
