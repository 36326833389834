import classNames from 'classnames/bind';
import React from 'react';
import Modal, { IModalProps } from '../Modal';
import { Button, IButtonProps } from '../Button';

export interface IConfirmationModalProps extends IModalProps {
  onConfirm: () => void;
  cancelText?: string;
  confirmText: string;
  loading?: boolean;
  buttonsCenter?: boolean;
  confirmButtonType?: IButtonProps['type'];
}

const ConfirmationModal = ({
  children,
  confirmButtonType,
  icon,
  isOpen,
  onCancel,
  onConfirm,
  title,
  cancelText,
  confirmText,
  loading,
  className,
  buttonsCenter,
}: IConfirmationModalProps) => {
  return (
    <Modal className={className} isOpen={isOpen} onCancel={onCancel} title={title} icon={icon}>
      <div className="mt-2 text-lg">{children}</div>
      <div
        className={classNames('mt-6 flex flex-col sm:flex-row  mb-3 sm:mb-2', {
          'sm:justify-center space-x-6': buttonsCenter,
          'sm:justify-end': !buttonsCenter,
        })}
      >
        {cancelText && (
          <Button
            className="uppercase w-full sm:w-[155px] order-1 sm:-order-1 whitespace-nowrap"
            type="cancel"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        <Button
          onClick={onConfirm}
          loading={loading}
          type={confirmButtonType}
          className="uppercase w-full sm:w-[155px] mb-3.5 sm:mb-0 sm:ml-2.5 whitespace-nowrap"
        >
          {confirmText}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  loading: undefined,
  buttonsCenter: false,
  cancelText: '',
  confirmButtonType: undefined,
};
export default ConfirmationModal;
