import React, { useEffect, useRef, useState } from 'react';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { Popover } from '@headlessui/react';
import { ArrowLeftIcon, XIcon } from '@heroicons/react/solid';
import MessageList, { DayType } from './components/MessageList';
import Image from '../../components/Image';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../../contexts/auth-context';
import { errorToast } from '../../../utils';
import { ImageType } from '../../../generated/graphql';
import { generateItems } from './utils';
import { usePatientOnlineStatus } from '../../../hooks/usePatientOnlineStatus';

export type MessageType = {
  id: number;
  text: string;
  from_user_id: number;
  to_user_id: number;
  created_at: string;
  __typename: 'chat_messages';
};

const MESSAGES_SUBSCRIPTION = gql`
  subscription messagesSubscription($user1: Int!, $user2: Int!) {
    chat_message(
      where: {
        _or: [
          { _and: { from_user_id: { _eq: $user1 }, to_user_id: { _eq: $user2 } } }
          { _and: { from_user_id: { _eq: $user2 }, to_user_id: { _eq: $user1 } } }
        ]
      }
    ) {
      from_user_id
      id
      text
      to_user_id
      created_at
    }
  }
`;

const SUBMIT_MESSAGES = gql`
  mutation InsertMessages($text: String!, $fromUserId: Int!, $toUserId: Int!) {
    insert_chat_message(objects: { text: $text, from_user_id: $fromUserId, to_user_id: $toUserId }) {
      returning {
        id
        text
        from_user_id
        to_user_id
      }
    }
  }
`;

type Props = {
  goBack?: () => void;
  withUser: {
    userId: string;
    avatar?: Pick<ImageType, 'url'>;
    name: string;
  };
};

const Chat = ({ goBack, withUser: { userId, avatar, name } }: Props) => {
  const [messageText, setMessageText] = useState('');
  const [generatedMessages, setGeneratedMessages] = useState<(MessageType | DayType)[]>([]);
  const authContext = useAuth();
  const { t } = useTranslation();
  const showOnline = usePatientOnlineStatus(userId);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = (config: { behavior: 'smooth' | 'auto' } = { behavior: 'auto' }) => {
    messagesEndRef.current?.scrollIntoView(config);
  };

  const [sendMessageMutation] = useMutation(SUBMIT_MESSAGES, {
    context: {
      clientName: 'chat',
    },
  });

  const {
    loading: loadingSubscription,
    error: errorSubscription,
    data: { chat_message: messages } = {},
  } = useSubscription(MESSAGES_SUBSCRIPTION, {
    variables: {
      user1: userId,
      user2: authContext.user!.id,
    },
  });

  // Show error if any
  useEffect(() => {
    if (errorSubscription) {
      errorToast(errorSubscription.message);
    }
  }, [errorSubscription]);

  const sendMessage = () => {
    if (messageText) {
      sendMessageMutation({
        variables: {
          text: messageText,
          fromUserId: authContext.user!.id,
          toUserId: userId,
        },
      });
      setMessageText('');
    }
  };

  useEffect(() => {
    if (messages) {
      const generatedItems = generateItems(messages);
      setGeneratedMessages(generatedItems);
    }
  }, [messages]);

  useEffect(() => scrollToBottom({ behavior: 'smooth' }), [messages]);

  // for fast jump to the end of messages after first messageLoading
  useEffect(() => {
    scrollToBottom();
  }, [loadingSubscription]);

  return (
    <div className="flex flex-col h-full">
      <div className="relative flex items-center px-3 py-2 border-b border-gray-100">
        {goBack ? (
          <button onClick={goBack} className="mr-2">
            <ArrowLeftIcon className="text-gray-500 h-7 w-7" aria-hidden="true" />
          </button>
        ) : (
          <Popover.Button className="mr-2">
            <XIcon className="text-gray-500 h-7 w-7" aria-hidden="true" />
          </Popover.Button>
        )}

        <Image className="object-cover w-8 h-8 rounded-full bg-slate-600" url={avatar?.url} />
        <span className="block ml-2 font-bold text-gray-600 truncate">{name}</span>
        <span
          className={classNames(
            showOnline ? 'bg-green-600' : 'bg-red-600',
            'absolute w-3 h-3 rounded-full border-2 border-white left-[70px] top-[8px]'
          )}
        />
      </div>
      <div
        className={`${
          loadingSubscription ? 'flex items-center justify-center' : 'relative'
        } w-full p-6 overflow-y-auto h-[47rem] sm:h-[25rem]`}
      >
        {loadingSubscription ? (
          <Spinner />
        ) : (
          messages && generatedMessages && <MessageList messages={generatedMessages as MessageType[]} />
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="flex items-center justify-between w-full p-3 border-t border-gray-100 overflow-hidden">
        <input
          type="text"
          placeholder={t('Message')}
          className="block w-full py-1 pl-4 mx-3 bg-gray-100 rounded-full outline-none focus:text-gray-700 focus:border-fuchsia-600"
          name="message"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          required
        />
        <button type="button" disabled={!messageText} onClick={sendMessage}>
          <svg
            className={`${
              messageText ? 'text-fuchsia-600' : 'text-gray-500'
            } w-8 h-8 origin-center transform rotate-90 `}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

Chat.defaultProps = {
  goBack: undefined,
};

export default Chat;
