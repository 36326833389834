import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { MessageType } from '../../view/widgets/Chat';

const useChatMessageListProvider = (messages: MessageType[] | undefined) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  const { t } = useTranslation();
  const monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const dayAgo = (date: string | undefined) => {
    const day = new Date(`${date}`);
    const today = new Date().toISOString().slice(0, 10);
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
    const monthNumber = Number(date?.substr(6).slice(0, 1)) - 1;

    if (today === date) {
      return t('today');
    }
    if (yesterday === date) {
      return t('yesterday');
    }
    return `${day.getDate()} ${t(monthNames[monthNumber])}`;
  };

  return [dayAgo, messagesEndRef, scrollToBottom] as const;
};

// eslint-disable-next-line import/no-default-export
export default useChatMessageListProvider;
