/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import MaskedInput from 'react-input-mask';
import { Controller, FieldError, useForm, UseFormRegister } from 'react-hook-form';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import {
  DoctorDocument,
  DoctorInfoUpdateInputType,
  DoctorType,
  MeDocument,
  useDoctorInfoUpdateMutation,
  useUploadUserAvatarMutation,
  useGeneratePresignedUrlLazyQuery,
} from '../../../generated/graphql';
import { infoToast, successToast } from '../../../utils';
import { useAuth } from '../../../contexts/auth-context';
import PhotoLoader from '../../components/PhotoLoader';

interface Props {
  doctor: Pick<DoctorType, 'details' | 'user' | 'specialization'>;
}

const ProfileDetailEdit = ({ doctor: { details, specialization, user } }: Props) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<DoctorInfoUpdateInputType>({
    defaultValues: { details, specialization, ...user },
    mode: 'onChange',
  });
  const { t } = useTranslation();
  const authContext = useAuth();
  const [avatar, setAvatar] = useState<File | null>(null);
  const [showEmailText, setShowEmailText] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [getGeneratePresignedUrl] = useGeneratePresignedUrlLazyQuery();
  const [doctorDataUpdate] = useDoctorInfoUpdateMutation({
    onCompleted: () => {
      successToast(t('Successfully updated your profile'));
    },
    refetchQueries: [
      { query: DoctorDocument, variables: { id: authContext.user?.doctorSet?.edges[0].node.id as string } },
    ],
    onError: (graphqlError) => {
      // eslint-disable-next-line no-console
      console.log(graphqlError);
    },
  });

  const [uploadUserAvatarMutation] = useUploadUserAvatarMutation();

  const handleProfileUpdate = useCallback(
    (doctorInfo: DoctorInfoUpdateInputType) => {
      doctorDataUpdate({
        variables: {
          input: {
            details: doctorInfo.details,
            specialization: doctorInfo.specialization,
            email: doctorInfo.email,
            firstName: doctorInfo.firstName,
            phone: doctorInfo.phone,
            secondName: doctorInfo.secondName,
          },
        },
      });
    },
    [doctorDataUpdate]
  );

  const handleFileChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target.files) {
      setAvatar(event.target.files[0]);
      setAvatarUrl(URL.createObjectURL(event.target.files[0]));
    } else {
      setAvatar(null);
      setAvatarUrl(undefined);
    }
  };

  const setNewAvatar = async () => {
    if (avatar) {
      const result = await getGeneratePresignedUrl({ variables: { filename: avatar?.name } });

      if (result.data?.getPresignedUrl) {
        await fetch(result.data?.getPresignedUrl, {
          method: 'PUT',
          body: avatar,
        });
        if (authContext.user?.id) {
          await uploadUserAvatarMutation({
            variables: {
              input: {
                userId: authContext.user.id,
                file: result.data?.getPresignedUrl,
              },
            },
            refetchQueries: [MeDocument, DoctorDocument],
          });
        }
      }
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        setNewAvatar();
        handleSubmit(handleProfileUpdate)();
        if (changeEmail && isValid) {
          setTimeout(() => authContext.logout(), 3000);
          setTimeout(() => infoToast(t('You are logged out. Please enter new email')), 3000);
        }
        setAvatar(null);
      }}
    >
      <div className="xl:grid xl:grid-cols-3 xl:space-x-5 space-y-5 xl:space-y-0">
        <div className="col-span-1 pb-6 grid items-center text-center bg-white rounded-xl shadow">
          <PhotoLoader
            handleFileChange={handleFileChange}
            avatar={user.avatar}
            localAvatar={avatar}
            localAvatarUrl={avatarUrl}
          />
        </div>
        <div className="col-span-2 bg-white p-6 text-center rounded-xl shadow">
          <div className="bg-white">
            <label htmlFor="avatar" className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
              {t('General information')}
            </label>
            <div className="grid grid-cols-4 gap-6 mt-5">
              {/* New input */}
              <InputField
                register={register}
                fieldName="firstName"
                title={t('First Name')}
                type="text"
                placeholder={t('Your first name...')}
                errors={errors.firstName}
                required
              />
              {/* New input */}
              <InputField
                register={register}
                fieldName="secondName"
                title={t('Second Name')}
                type="text"
                placeholder={t('Your second name...')}
                errors={errors.secondName}
                required
              />
              {/* New input */}
              <div className="col-span-4 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
                <label htmlFor="phone" className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
                  {t('Phone')}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Controller
                    render={({ field: { onChange, value, onBlur } }) => (
                      <MaskedInput
                        mask="+7 (999) 999 99 99"
                        maskChar={null}
                        id="phone"
                        placeholder="+7 (XXX) XXX XX XX"
                        type="tel"
                        inputMode="tel"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onBlur={onBlur}
                        value={value!}
                        className={
                          errors.phone
                            ? 'block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
                            : 'block max-w-xl2 w-full shadow-sm bg-gray-50 text-gray-500 focus:ring-violet-500 focus:border-violet-500 sm:text-sm border-gray-300 rounded-md'
                        }
                      />
                    )}
                    control={control}
                    name="phone"
                    rules={{
                      required: true,
                      pattern: /^[+]?\d[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}([-\s.]?[0-9]{2}){2}$/i,
                    }}
                  />
                </div>
              </div>
              {/* New input */}
              <div className="col-span-4 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
                <label htmlFor="email" className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
                  {t('Email address')}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register('email', {
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      onChange: (e) => {
                        setShowEmailText(!(user.email === e.target.value));
                        setChangeEmail(!(user.email === e.target.value));
                      },
                      required: true,
                    })}
                    type="email"
                    inputMode="email"
                    id="email"
                    placeholder={t('Enter your email')}
                    className={
                      errors.email
                        ? 'block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
                        : 'block max-w-xl2 w-full shadow-sm bg-gray-50 text-gray-500 focus:ring-violet-500 focus:border-violet-500 sm:text-sm border-gray-300 rounded-md'
                    }
                    required
                  />
                  <div className={classNames(showEmailText ? '' : 'hidden', 'text-violet-500 pt-2')}>
                    {t('Deauthorizes you when you change your email')}
                  </div>
                </div>
              </div>
              {/* New input */}
              <InputField
                register={register}
                fieldName="specialization"
                title={t('Specialization')}
                type="text"
                placeholder={t('Enter your specialization')}
                errors={errors.specialization}
                required
              />
              {/* New input */}
              <div className="col-span-4 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
                <label htmlFor="details" className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
                  {t('About me')}
                </label>
                <textarea
                  {...register('details')}
                  name="details"
                  id="details"
                  placeholder={t('Write a few sentences about yourself.')}
                  rows={3}
                  className={
                    errors.details
                      ? 'block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
                      : 'block max-w-xl2 w-full shadow-sm bg-gray-50 text-gray-500 focus:ring-violet-500 focus:border-violet-500 sm:text-sm border-gray-300 rounded-md'
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt-8 w-full sm:w-1/2 sm:pr-3 space-y-3 max-w-xl2 sm:space-y-0 sm:space-x-3">
            <button
              type="submit"
              className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-violet-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-violet-500 focus:outline-none focus:ring-violet-500 focus:ring-offset-2 sm:flex-1"
            >
              {t('Save')}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
function InputField({
  register,
  placeholder,
  fieldName,
  type,
  errors,
  title,
  required,
}: {
  register: UseFormRegister<DoctorInfoUpdateInputType>;
  placeholder: string;
  fieldName: 'details' | 'email' | 'firstName' | 'phone' | 'secondName' | 'specialization';
  type: string;
  errors?: FieldError;
  title: string;
  required: boolean;
}) {
  return (
    <div className="col-span-4 sm:col-span-2 sm:gap-4 sm:items-start space-y-3 sm:space-y-2">
      <label htmlFor={fieldName} className="block text-sm font-medium text-left text-black sm:mt-px sm:pt-2">
        {title}
      </label>
      <input
        {...register(fieldName, {
          required,
        })}
        id={fieldName}
        name={fieldName}
        type={type}
        placeholder={placeholder}
        autoComplete={fieldName}
        className={
          errors
            ? 'block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
            : 'block max-w-xl2 w-full shadow-sm bg-gray-50 text-gray-500 focus:ring-violet-500 focus:border-violet-500 sm:text-sm border-gray-300 rounded-md'
        }
      />
    </div>
  );
}

InputField.defaultProps = {
  required: false,
  errors: undefined,
};

export default ProfileDetailEdit;
