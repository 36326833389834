import React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileDetailEdit from '../../widgets/Profile';
import { DoctorType, useDoctorQuery } from '../../../generated/graphql';
import { useAuth } from '../../../contexts/auth-context';
import Preloader from '../../components/Preloader';
import PageNotFound from '../../components/PageNotFound';

const ProfilePage = () => {
  const authContext = useAuth();
  const { t } = useTranslation();

  const { data, loading, error } = useDoctorQuery({
    variables: {
      id: authContext.user?.doctorSet?.edges[0].node.id as string,
    },
  });

  if (loading) {
    return (
      <div className="h-[70vh] col-span-7 items-center flex justify-center">
        <Preloader />
      </div>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return <PageNotFound />;
  }

  const doctor = data?.doctor as DoctorType;

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">{t('Profile')}</h1>
      <ProfileDetailEdit doctor={doctor} />
    </>
  );
};

export default ProfilePage;
