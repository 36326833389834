import React from 'react';

const NewMedicinePage = () => {
  return (
    <div className="lg:col-span-9 xl:col-span-9 bg-white shadow sm:rounded-lg">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-8">
        <div className="space-y-8 sm:space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">New medicine</h2>
          </div>
          <div className="text-left" />
        </div>
      </div>
    </div>
  );
};

export default NewMedicinePage;
