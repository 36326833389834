import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { BellIcon } from '@heroicons/react/outline';
import { NotificationItem } from './components/NotificationItem';
import { useDoctorPushNotificationsQueryProvider } from '../../../providers/doctor-push-notifications/useDoctorPushNotificationsQueryProvider';

const NotificationDropdown = () => {
  const { t } = useTranslation();
  const [doctorNotifications, { data, loading, error }] = useDoctorPushNotificationsQueryProvider();
  const totalCount: number | undefined = data?.doctorPushNotifications.totalCount;

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="bg-white flex text-slate-600 items-center text-sm rounded-full focus:outline-none focus:ring-offset-2">
          <div className="relative">
            <BellIcon className="h-10 w-10" />
            <div className="absolute top-0 px-[7px] bg-red-400 rounded-full text-white">
              {!loading && !error && totalCount}
            </div>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-72 sm:w-96 rounded-md shadow-lg py-1 bg-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="text-center text-gray-700 p-2">{t('Notifications')}</div>
          <div className="max-h-96 overflow-auto space-y-0.5 ">
            {doctorNotifications.length > 0 && !error ? (
              doctorNotifications.map((notify) => <NotificationItem key={notify.id} {...notify} />)
            ) : (
              <div className="block px-4 py-5 text-sm bg-white text-gray-700'">{t("You don't have notifications")}</div>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NotificationDropdown;
