import React, { useMemo, useState } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useMedicinesWithPatientMedicinesQuery } from '../../../../../generated/graphql';

interface ISearchItem {
  id: string;
  name: string;
}

interface Props {
  onChange: (value: string) => void;
  errors: any;
}

const MedicineSearch = ({ onChange, errors }: Props) => {
  const [query, setQuery] = useState('');
  const [selectedPerson, setSelectedPerson] = useState();
  const { id: patientId } = useParams();

  const { data } = useMedicinesWithPatientMedicinesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      patient_id: patientId as string,
    },
  });

  const medicines: ISearchItem[] = useMemo(() => {
    let result: ISearchItem[] = [];
    if (data) {
      // const patientMedicinesIDs = data.patientMedicinesByPatientPk.map(({ medicine }) => medicine.id);
      const hashedSearchResults: any = {};

      data.medicines.edges.forEach(({ node }) => {
        if (!hashedSearchResults[node.name]) {
          hashedSearchResults[node.name] = node;
        }
      });

      result = Object.values(hashedSearchResults);
    }
    return result;
  }, [data]);

  const filteredMeds =
    query === ''
      ? medicines
      : medicines.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
      <div className="relative mt-1">
        <Combobox.Input
          id="searchMedicine"
          className={
            errors
              ? `block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md`
              : `block w-full shadow-sm focus:ring-violet-500 focus:border-violet-500 sm:text-sm border-gray-300 rounded-md`
          }
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(med: string) => med}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredMeds.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredMeds.map((med) => (
              <Combobox.Option
                key={med.id}
                value={med.name}
                onClick={() => {
                  onChange(med.id);
                }}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-violet-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{med.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-violet-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default MedicineSearch;
