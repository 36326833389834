import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { SeizureType } from '../../../../../generated/graphql';
import ListHeader from '../../../../layout/ListHeader';
import ListBody from '../../../../layout/ListBody';
import Card from '../../../../layout/Card';

interface Props {
  patientSeizures: SeizureType[];
}

const PatientSeizureList = ({ patientSeizures }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const seizureIntensity: any = {
    A_1: t('Minor seizure'),
    A_2: t('Medium seizure'),
    A_3: t('Major seizure'),
    A_4: t('Severe seizure'),
  };

  return (
    <>
      <ListHeader
        title={t('Seizures')}
        actionButton={
          patientSeizures.length > 3 && (
            <Link
              to={`/seizures/patient?id=${id}`}
              className="px-4 py-2
              bg-white border-gray-300 ring-2 ring-gray-300
              text-sm font-medium text-gray-700 text-center
              rounded-lg md:rounded-xl sm:rounded-md
              hover:bg-gray-100
              focus:ring-2 focus:ring-gray-300 focus:bg-gray-100 focus:text-black"
            >
              {`${t('View all')} (${patientSeizures.length})`}
            </Link>
          )
        }
      />
      <ListBody emptyStateText={t('This patient has no seizures yet')}>
        {patientSeizures.length > 0
          ? // limit the number of seizures displayed with slice
            patientSeizures.slice(0, 5).map((seizure) => (
              <Card key={seizure.id}>
                <div className="grid grid-col-2 space-y-3 justify-between">
                  <div className="text-sm font-medium">
                    {`${t('Date')}:  ${format(new Date(seizure.date), `dd/MM/yyyy`)}`}
                    {`${format(new Date(seizure.date), `- ${t('Time')}: HH:MM`)}`}
                  </div>
                  <div className="text-sm text-gray-600">{`${seizureIntensity[seizure.intensity]}: ${
                    seizure.duration
                  } ${t('sec')}`}</div>
                </div>
              </Card>
            ))
          : null}
      </ListBody>
    </>
  );
};

export default PatientSeizureList;
