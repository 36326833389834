import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PatientType, usePatientQuery } from '../../../generated/graphql';
import Preloader from '../../components/Preloader';
import PageNotFound from '../../components/PageNotFound';
import PatientDetail from '../../widgets/PatientDetail';

const PatientPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = usePatientQuery({ variables: { id: id! } });
  const patient = data?.patient as PatientType;

  if (loading) {
    return (
      <div className="h-[70vh] col-span-7 items-center flex justify-center">
        <Preloader />
      </div>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return <PageNotFound />;
  }

  return (
    <div className="w-full">
      <div className="flex items-start justify-between">
        <h2 id="slide-over-heading" className="text-2xl font-medium text-gray-900">
          {t('Patient card')}
        </h2>
      </div>
      {patient && <PatientDetail patient={patient} />}
    </div>
  );
};

export default PatientPage;
