import React, { useMemo } from 'react';
import { useDoctorPatientsQuery } from 'src/generated/graphql';
import ChatWidget from '../ChatWidget';

const PatientsChatWidget = () => {
  const { data } = useDoctorPatientsQuery();

  const patients = useMemo(() => {
    return (
      data?.doctorPatients.edges.map((edge) => ({
        id: edge.node.patient.user.id,
        // TODO: we should fix NOT UNIQ issue in the BACKEND, for avoid same key issues
        name: `${edge.node.patient.user.firstName}  ${edge.node.patient.user.secondName}`,
        imageUrl: edge.node.patient.user.avatar?.url,
      })) || []
    );
  }, [data]);

  return <ChatWidget people={patients} />;
};

export default PatientsChatWidget;
