import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { PrescriptionMedicineType, useMedicinesWithPatientMedicinesQuery } from '../../../../generated/graphql';
import DisclosureDropdown from '../../../components/DisclosureDropdown';
import { Button } from '../../../components';
import MedicineSlideOverForm, { IMedicineFormData } from '../../MedicineSlideOverForm';
import { usePrescriptionPageProvider } from '../../../../providers';

interface Props {
  medicine: PrescriptionMedicineType & any;
  changeMedicine?: boolean;
}

const PrescriptionMedicine: React.FC<Props> = ({ changeMedicine, medicine }) => {
  const { id: patientId } = useParams();
  const { t } = useTranslation();

  const { updatePrescriptionMedicine, removePrescriptionMedicine } = usePrescriptionPageProvider();

  const [isMedicineFormOpen, setIsMedicineFormOpen] = useState(false);

  const updateMedicineToForm = (prescriptionMedicine: IMedicineFormData) => {
    updatePrescriptionMedicine(prescriptionMedicine);
    setIsMedicineFormOpen(false);
  };

  const medicineIntakeState: any = {
    A_0: t('Anytime'),
    A_1: t('Hungry'),
    A_2: t('Eating'),
    A_3: t('Full'),
    A_4: t('Before sleep'),
    A_5: t('Awoke'),
  };

  const { data } = useMedicinesWithPatientMedicinesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      patient_id: patientId as string,
    },
  });

  const medicineName =
    data?.medicines.edges.find((item) => item.node.id === medicine?.medicineId)?.node.name || medicine?.medicine?.name;

  const medicineDescription = [
    {
      title: 'Duration',
      value: medicine?.duration,
    },
    {
      title: 'Day frequency',
      value: medicine?.frequencyDay,
    },
    {
      title: 'Days interval',
      value: medicine?.intervalDay,
    },
    {
      title: 'Intake quantity',
      value: medicine?.intakeQuantity,
    },
    {
      title: 'Intake state',
      value: medicineIntakeState[medicine?.intakeState],
    },
    {
      title: 'Intake time',
      value: medicine.intakeTimes
        ? `${medicine?.intakeTimes.map((times: any) => {
            return times?.intakeTime;
          })}`
        : `${medicine?.medicineintaketimeSet?.edges.map((edge: any) => {
            return edge.node.intakeTime;
          })}`,
    },
  ];

  return (
    <div className="flex flex-wrap gap-2 py-1 items-center w-full">
      <DisclosureDropdown title={medicineName}>
        {medicine && (
          <div>
            <div className="mt-1">
              <dl className="mr-5 md:grid md:grid-cols-2 gap-2 space-x-5">
                <div className="ml-5 col-span-1 py-2 sm:py-4 sm:grid sm:grid-cols-1 sm:gap-1">
                  <dt className="text-sm font-medium text-gray-900">{t('Medicine')}</dt>
                  <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{medicineName}</dd>
                </div>
                <div className="col-span-1 border-t md:border-t-0 border-white py-2 sm:py-4 sm:grid sm:grid-cols-1 sm:gap-1">
                  <dt className="text-sm font-medium text-gray-900">{t('Start date')}</dt>
                  <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{`${format(
                    new Date(medicine?.startDate),
                    'dd-MM-yyyy'
                  )}`}</dd>
                </div>
                {medicineDescription.map(({ title, value }) => (
                  <div
                    key={title}
                    className="col-span-1 border-t border-white  py-2 sm:py-4 sm:grid sm:grid-cols-1 sm:gap-1"
                  >
                    <dt className="text-sm font-medium text-gray-900">{t(title)}</dt>
                    <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">{value}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        )}
        {changeMedicine && (
          <div className="flex flex-row space-x-2 justify-end">
            <Button className="w-1/4" type="danger" onClick={() => removePrescriptionMedicine(medicine.id)}>
              <TrashIcon className="h-5 w-5" />
            </Button>
            <Button className="w-1/4" onClick={() => setIsMedicineFormOpen(true)}>
              <PencilIcon className="h-5 w-5" />
            </Button>
          </div>
        )}
      </DisclosureDropdown>
      <MedicineSlideOverForm
        defaultValues={medicine}
        onSubmit={updateMedicineToForm}
        onCancel={() => setIsMedicineFormOpen(false)}
        isOpen={isMedicineFormOpen}
      />
    </div>
  );
};
PrescriptionMedicine.defaultProps = {
  changeMedicine: false,
};

export default PrescriptionMedicine;
