import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface Props {
  patientSeizures: any[];
}

const PatientSeizuresTable = ({ patientSeizures }: Props) => {
  const { t } = useTranslation();

  const seizureIntensity: any = {
    A_1: t('Minor seizure'),
    A_2: t('Medium seizure'),
    A_3: t('Major seizure'),
    A_4: t('Severe seizure'),
  };
  const seizureStatus: any = {
    A_1: t('Confirmed'),
    A_2: t('Pending'),
    A_3: t('Rejected'),
  };

  return (
    <table className="lg:min-w-full min-w-[900px] divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900">
            {t('Data')}
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            {t('Intensity')}
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            {t('Duration')}
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            {t('Trigger')}
          </th>
          <th scope="col" className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
            {t('Status')}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y items-center divide-gray-200 bg-white">
        {patientSeizures.map((patient, index) => (
          <tr key={patient.id} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
            <td className="whitespace-nowrap px-5 py-4 text-sm text-gray-900">
              {`${format(new Date(patient.date), 'dd-MM-yyyy')}`}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{seizureIntensity[patient.intensity]}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
              {patient.duration} {t('seconds')}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{patient.trigger}</td>
            <td className="whitespace-nowrap px-1 py-4 text-sm text-gray-900">{seizureStatus[patient.status]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PatientSeizuresTable;
