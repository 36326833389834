import React from 'react';
import classNames from 'classnames/bind';

interface ICardProps {
  children: React.ReactNode;
  className?: string;
}

const Card = ({ children, className }: ICardProps) => {
  return (
    <div
      className={classNames(
        className,
        'bg-white rounded-lg md:rounded-xl border-violet-200 shadow overflow-hidden p-4 sm:rounded-md'
      )}
    >
      {children}
    </div>
  );
};

Card.defaultProps = {
  className: '',
};

export default Card;
