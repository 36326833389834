import React from 'react';
import { useTranslation } from 'react-i18next';
import Calendar from '../../../Calendar';
import { SeizureTypeConnection } from '../../../../../generated/graphql';

interface Props {
  data: SeizureTypeConnection;
}

const SeizureCalendar = ({ data }: Props) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const daysOfWeekShortNames = [t('Mn'), t('Tu'), t('Wd'), t('Th'), t('Fr'), t('St'), t('Sn')];

  return (
    <div>
      {data && (
        <Calendar year={currentYear} month={currentMonth} data={data} daysOfWeekShortNames={daysOfWeekShortNames} />
      )}
    </div>
  );
};

export default SeizureCalendar;
