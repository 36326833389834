import { useMemo } from 'react';
import { useDoctorSeizuresQuery } from '../../generated/graphql';

const usePatientSeizuresQueryProvider = (patientId: string | null, first: number, offset: number) => {
  const response = useDoctorSeizuresQuery({
    variables: {
      id: patientId,
      first,
      offset,
    },
    fetchPolicy: 'no-cache',
  });

  const patientSeizures = useMemo(() => {
    return (
      response.data?.doctorSeizures.edges.map((edge) => ({
        id: edge.node.id,
        date: edge.node.date,
        duration: edge.node.duration,
        intensity: edge.node.intensity,
        trigger: edge.node.trigger,
        status: edge.node.status,
        user: edge.node.patient?.user,
      })) || []
    );
  }, [response.data]);

  return [patientSeizures, response] as const;
};

// eslint-disable-next-line import/no-default-export
export default usePatientSeizuresQueryProvider;
