import React, { useCallback } from 'react';

// graphql
// form
import { Controller, useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/auth-context';
import { DoctorUserDataInputType, useDoctorUserDataSetMutation, UserType } from '../../../generated/graphql';
import { Button } from '../../components/Button';

const defaultValuesConstructor = (me?: UserType) => ({
  firstName: me?.firstName || '',
  secondName: me?.secondName || '',
  thirdName: me?.thirdName || '',
  specialization: '',
  details: '',
});

const UserDataForm: React.FC = () => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { handleSubmit, formState, control } = useForm<DoctorUserDataInputType>({
    mode: 'onChange',
    defaultValues: defaultValuesConstructor(),
  });

  const [doctorUserDataSet, { loading }] = useDoctorUserDataSetMutation({
    onCompleted: (data) => {
      authContext.setUser(data.userDataSet.user as UserType);
      navigate('/');
    },
  });

  const handleRegistration = useCallback(
    (payload: DoctorUserDataInputType) => {
      if (payload) {
        const updatedPayload: DoctorUserDataInputType = {
          ...payload,
        };

        doctorUserDataSet({
          variables: {
            doctorInput: updatedPayload,
          },
        });
      }
    },
    [doctorUserDataSet]
  );

  const saveUserData = useCallback(
    async ({ ...data }: DoctorUserDataInputType) => {
      await handleRegistration(data);
    },
    [handleRegistration]
  );

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t('Sign in to your account')}</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <span className="block text-sm font-medium text-gray-700">{t('First Name')}:</span>
              <div className="mt-1">
                <Controller
                  render={({ field: { onChange } }) => (
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => onChange(e.target.value)}
                      type="text"
                      inputMode="text"
                      placeholder={t('First Name')}
                    />
                  )}
                  control={control}
                  name="firstName"
                  rules={{
                    required: true,
                  }}
                />
              </div>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">{t('Second Name')}:</span>
              <div className="mt-1">
                <Controller
                  render={({ field: { onChange } }) => (
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => onChange(e.target.value)}
                      type="text"
                      inputMode="text"
                      placeholder={t('Second Name')}
                    />
                  )}
                  control={control}
                  name="secondName"
                  rules={{
                    required: true,
                  }}
                />
              </div>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">{t('Third Name')}:</span>
              <div className="mt-1">
                <Controller
                  render={({ field: { onChange } }) => (
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => onChange(e.target.value)}
                      type="text"
                      inputMode="text"
                      placeholder={t('Third Name')}
                    />
                  )}
                  control={control}
                  name="thirdName"
                  rules={{
                    required: true,
                  }}
                />
              </div>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">{t('Specialization')}:</span>
              <div className="mt-1">
                <Controller
                  render={({ field: { onChange } }) => (
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => onChange(e.target.value)}
                      type="text"
                      inputMode="text"
                      placeholder={t('Specialization')}
                    />
                  )}
                  control={control}
                  name="specialization"
                  rules={{
                    required: true,
                  }}
                />
              </div>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">{t('Details')}:</span>
              <div className="mt-1">
                <Controller
                  render={({ field: { onChange } }) => (
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => onChange(e.target.value)}
                      type="text"
                      inputMode="text"
                      placeholder={t('Details')}
                    />
                  )}
                  control={control}
                  name="details"
                />
              </div>
            </div>

            <Button
              className="w-full"
              disabled={!formState.isValid || loading}
              onClick={handleSubmit(saveUserData)}
              type="submit"
            >
              {t('Next')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDataForm;
