import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatDisplayTime = (value: string) => {
  return format(parseISO(value), 'HH:mm');
};

const convertToMinutes = (sec: number): number => {
  return Math.floor(sec / 60);
};

const convertToSeconds = (sec: number): number => {
  return sec % 60;
};

const UTCLocaleDateTimeConverter = (dateTime: string, toUTC?: boolean) => {
  return new Date(
    Date.parse(new Date(dateTime).toUTCString()) + new Date().getTimezoneOffset() * 60000 * (toUTC ? 1 : -1)
  );
};

const noDelaySetInterval = (cb: () => void, interval: number) => {
  cb();
  return setInterval(cb, interval);
};

const errorToast = (message: string, options: any = {}): void => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    pauseOnHover: true,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};

const successToast = (message: string, options: any = {}): void => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    pauseOnHover: true,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};

const infoToast = (message: string, options: any = {}): void => {
  toast.info(message, {
    position: 'top-right',
    autoClose: 5000,
    pauseOnHover: true,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};

const getFormatPhone = (phone: string): string => {
  if (phone.length === 12) {
    phone = ''.concat(
      phone.slice(0, 2),
      ' (',
      phone.slice(2, 5),
      ') ',
      phone.slice(5, 8),
      ' ',
      phone.slice(8, 10),
      ' ',
      phone.slice(10)
    );
  }
  return phone;
};

const isValidPhone = (phone: string): boolean => {
  const regExp = /^[+]?\d[-\s.]?[(]?[9][0-9]{2}[)]?[-\s.]?[0-9]{3}([-\s.]?[0-9]{2}){2}[-\s.]?$/i;
  return phone.search(regExp) !== -1;
};

const parseInvalidInputError = (error: string): string => {
  const jsonData = JSON.parse(error.replaceAll("'", '"'));
  const errorField = Object.keys(jsonData)[0];
  const errorMessage = jsonData[errorField][0];
  return errorMessage.replace('This', capitalizeFirstLetter(errorField)).replace('blank', 'empty');
};

const isInvalidFieldError = (error: string): boolean => {
  return !!parseInvalidInputError(error);
};

const prefixArbitraryDateToTime = (v: string): string => {
  return new Date().toISOString().slice(0, 11) + v;
};

const postfixArbitraryTimeToDate = (v: string): string => {
  return `${v}T00:00`;
};

export {
  formatDisplayTime,
  format,
  convertToMinutes,
  convertToSeconds,
  UTCLocaleDateTimeConverter,
  noDelaySetInterval,
  errorToast,
  successToast,
  infoToast,
  isInvalidFieldError,
  parseInvalidInputError,
  getFormatPhone,
  isValidPhone,
  prefixArbitraryDateToTime,
  postfixArbitraryTimeToDate,
};
