import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext<any>(undefined!);

export const DataProvider = ({ children }: any) => {
  const [data, setData] = useState(false);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <DataContext.Provider value={{ data, setData }}>{children}</DataContext.Provider>;
};

export const useData = () => useContext(DataContext);
