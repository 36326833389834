import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DoctorCreatePrescriptionMedicineInputType,
  DoctorPatientPrescriptionDocument,
  useCreatePrescriptionMedicineMutation,
  useDoctorPatientPrescriptionQuery,
  useDoctorRemovePrescriptionMedicineMutation,
  useDoctorUpdatePrescriptionMedicineMutation,
  useDoctorUpdatePrescriptionMutation,
} from '../../generated/graphql';
import { successToast } from '../../utils';

const usePrescriptionPageProvider = (prescriptionId?: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const response = useDoctorPatientPrescriptionQuery({
    variables: {
      id: prescriptionId!,
    },
    skip: !prescriptionId,
    fetchPolicy: 'no-cache',
  });

  const [doctorUpdatePrescriptionMutation] = useDoctorUpdatePrescriptionMutation({
    refetchQueries: [DoctorPatientPrescriptionDocument],
    onCompleted: () => {
      navigate(-1);
      successToast(t('Prescription successfully updated'));
    },
  });

  const [createPrescriptionMedicineMutation] = useCreatePrescriptionMedicineMutation({
    refetchQueries: [DoctorPatientPrescriptionDocument],
    onCompleted: () => {
      successToast(t('Medicine successfully created'));
    },
  });
  // Исправить type
  const createPrescriptionMedicine = (medicine: any) => {
    createPrescriptionMedicineMutation({
      variables: {
        input: medicine,
      },
    });
  };

  const [updatePrescriptionMedicineMutation] = useDoctorUpdatePrescriptionMedicineMutation({
    refetchQueries: [DoctorPatientPrescriptionDocument],
    onCompleted: () => {
      successToast(t('Medicine successfully updated'));
    },
  });
  // Исправить type
  const updatePrescriptionMedicine = (updateMedicine: any) => {
    updatePrescriptionMedicineMutation({
      variables: {
        input: updateMedicine,
      },
    });
  };

  const [removePrescriptionMedicineMutation] = useDoctorRemovePrescriptionMedicineMutation({
    refetchQueries: [DoctorPatientPrescriptionDocument],
    onCompleted: () => {
      successToast(t('Medicine successfully deleted'));
    },
  });

  const removePrescriptionMedicine = (id: string) => {
    removePrescriptionMedicineMutation({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  const PrescriptionDescription = useMemo(() => {
    return response.data?.doctorPatientPrescription.description || '';
  }, [response.data?.doctorPatientPrescription.description]);

  const PatientPrescriptionMedicines = useMemo(() => {
    return (
      (response.data?.doctorPatientPrescription.prescriptionmedicineSet.edges.map((edge) => ({
        prescriptionId: response.data?.doctorPatientPrescription.id,
        id: edge.node.id,
        frequencyDay: edge.node.frequencyDay,
        intakeQuantity: edge.node.intakeQuantity,
        intakeState: edge.node.intakeState,
        intakeTimes: edge.node.medicineintaketimeSet.edges.map(
          // eslint-disable-next-line no-new-object
          (time) => new Object({ intakeTime: time.node.intakeTime })
        ),
        intervalDay: edge.node.intervalDay,
        medicineId: edge.node.medicine.id,
        startDate: edge.node.startDate,
        duration: edge.node.duration,
      })) as DoctorCreatePrescriptionMedicineInputType[]) || []
    );
  }, [response.data]);

  return {
    PatientPrescriptionMedicines,
    PrescriptionDescription,
    removePrescriptionMedicine,
    updatePrescriptionMedicine,
    createPrescriptionMedicine,
    doctorUpdatePrescriptionMutation,
    response,
  } as const;
};

// eslint-disable-next-line import/no-default-export
export default usePrescriptionPageProvider;
