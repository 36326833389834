import React from 'react';
import { useTranslation } from 'react-i18next';
import { PatientFileType } from '../../../../generated/graphql';
import ListHeader from '../../../layout/ListHeader';
import ListBody from '../../../layout/ListBody';
import FileCard from './components/FileCard';

interface IPatientFileListProps {
  patientFiles: PatientFileType[];
  children: React.ReactNode;
}

const PatientFileList = ({ patientFiles, children }: IPatientFileListProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <ListHeader title={t('Patient files')} />
      <ListBody emptyStateText={t('No files found')}>
        {children}
        {patientFiles.length > 0
          ? patientFiles.reverse().map((patientFile) => (
              <FileCard key={patientFile.id} patientFileId={patientFile.id} file={patientFile.file}>
                {patientFile.file.title}
              </FileCard>
            ))
          : null}
      </ListBody>
    </div>
  );
};

export default PatientFileList;
