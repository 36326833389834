import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDoctorPatientsQuery } from '../../../generated/graphql';
import Preloader from '../../components/Preloader';
import NoElementFound from '../../components/NoElementFound';
import PageNotFound from '../../components/PageNotFound';
import PaginationList from '../../components/PaginationList';
import PatientCard from '../../widgets/PatientCard';

const itemsOnPage = 6;

const PatientsPage = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const { data, loading, error } = useDoctorPatientsQuery({
    variables: {
      first: itemsOnPage,
      offset: itemsOnPage * (pageNumber - 1),
    },
  });
  const { t } = useTranslation();

  const patients = useMemo(() => {
    return (
      data?.doctorPatients.edges.map((edge) => ({
        id: edge.node.patient.id,
        userId: edge.node.patient.user?.id,
        // TODO: we should fix NOT UNIQ issue in the BACKEND, for avoid same key issues
        name: `${edge.node.patient.user.firstName}  ${edge.node.patient.user.secondName}`,
        phone: edge.node.patient.user.phone,
        imageUrl: edge.node.patient.user.avatar?.url,
      })) || []
    );
  }, [data]);

  if (loading) {
    return (
      <div className="h-[70vh] col-span-7 items-center flex justify-center">
        <Preloader />
      </div>
    );
  }

  if (patients.length === 0) {
    return <NoElementFound title={t('Patients list is empty')} />;
  }

  if (error) {
    return <PageNotFound />;
  }

  const totalCount: number | undefined = data?.doctorPatients.totalCount;

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">{t('Patients')}</h1>
      <div className="lg:col-span-9 xl:col-span-9">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {patients.map((person) => (
            <PatientCard key={person.id} {...person} />
          ))}
        </ul>
        {totalCount && (
          <div className="mt-4">
            <PaginationList
              itemsOnPage={itemsOnPage}
              totalCount={totalCount}
              selectedPage={pageNumber}
              onPageSelect={setPageNumber}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PatientsPage;
