import React, { useCallback } from 'react';
import moment from 'moment';

// graphql
import { useTranslation } from 'react-i18next';
import { useDoctorRegisterMutation } from '../../../../generated/graphql';

// i18n
// hooks
import { useTimer } from '../../../../hooks/useTimer';

// context
import { useAuth } from '../../../../contexts/auth-context';

function formatTimerDuration(duration: number): string {
  const timerObject = moment.duration(duration, 'seconds');
  return `${timerObject.minutes()}:${timerObject.seconds() < 10 ? `0${timerObject.seconds()}` : timerObject.seconds()}`;
}

const timerDurationInSeconds = Number(process.env.REACT_APP_SMS_RESEND_TIMER || 59);

const ResendSMS = () => {
  const authContext = useAuth();
  const { t } = useTranslation();
  const [duration, setDuration] = useTimer(timerDurationInSeconds);

  const [doctorRegistration] = useDoctorRegisterMutation({
    onCompleted: () => {
      setDuration(timerDurationInSeconds);
    },
  });

  const handleRegistration = useCallback(() => {
    doctorRegistration({
      variables: {
        input: {
          phone: authContext.user!.phone!,
          email: authContext.user!.email!,
          singIn: null,
        },
      },
    });
  }, [doctorRegistration, authContext.user]);

  return duration > 0 ? (
    <div color="dark">{`${t('Re-send SMS after')} ${formatTimerDuration(duration)} ${t('seconds')}.`}</div>
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={handleRegistration} color="warning">
      {t('Re-send SMS')}.
    </div>
  );
};

export default ResendSMS;
