import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { t } from 'i18next';
import {
  DoctorCreatePrescriptionMedicineInputType,
  PrescriptionMedicineIntakeState,
  PrescriptionMedicineType,
} from '../../../generated/graphql';
import MedicineSearch from './components/MedicineSearch';
import MedicineIntakeTime from './components/MedicineIntakeTime';
import InputField from '../../components/InputField';
import { Button } from '../../components/Button';
import SlideOver from '../../layout/SlideOver';

const medicineIntakeState: any = {
  A_0: t('Anytime'),
  A_1: t('Hungry'),
  A_2: t('Eating'),
  A_3: t('Full'),
  A_4: t('Before sleep'),
  A_5: t('Awoke'),
};

export interface IMedicineFormData
  extends Pick<
    PrescriptionMedicineType,
    'startDate' | 'duration' | 'intakeQuantity' | 'frequencyDay' | 'intervalDay' | 'intakeState'
  > {
  medicineId: string;
  intakeTimes: { intakeTime: string }[];
  prescriptionId: string;
}

interface IMedicineSlideOverFormProps {
  onSubmit: (medicine: IMedicineFormData) => void;
  onCancel: () => void;
  isOpen: boolean;
  defaultValues?: DoctorCreatePrescriptionMedicineInputType;
  prescriptionId?: string;
}

const MedicineSlideOverForm = ({
  prescriptionId,
  defaultValues,
  onSubmit,
  onCancel,
  isOpen,
}: IMedicineSlideOverFormProps) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm<IMedicineFormData>({
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      intakeTimes: defaultValues?.intakeTimes?.map((time) => time!) || [{}],
      prescriptionId: prescriptionId || defaultValues?.prescriptionId || '',
      medicineId: defaultValues?.medicineId,
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <SlideOver onCancel={onCancel} isOpen={isOpen} onClose={onCancel}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-4 space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{t('План приема лекарства')}</h3>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="border-t border-gray-200 pt-2">
                <label htmlFor="headlessui-combobox-input-4" className="block text-sm font-medium text-gray-700">
                  {t('Medicine searching')}
                </label>
                <Controller
                  render={({ field: { onChange } }) => (
                    <MedicineSearch onChange={onChange} errors={errors.medicineId} />
                  )}
                  control={control}
                  name="medicineId"
                  rules={{
                    required: true,
                  }}
                />
              </div>
              <InputField
                {...register('startDate', { required: true })}
                type="date"
                title={t('Start date')}
                error={errors.startDate}
              />
              <InputField
                {...register('duration', { required: true })}
                type="number"
                title={t('Days amount')}
                error={errors.duration}
              />
              <InputField
                {...register('intakeQuantity', { required: true })}
                type="number"
                title={t('Intake quantity')}
                error={errors.intakeQuantity}
              />
              <MedicineIntakeTime control={control} register={register} errors={errors.intakeTimes} />
              <InputField
                type="number"
                {...register('frequencyDay', { required: true })}
                title={t('Day frequency')}
                error={errors.frequencyDay}
              />
              <InputField
                type="number"
                {...register('intervalDay', { required: true })}
                title={t('Days interval')}
                error={errors.intervalDay}
              />

              <div className="border-t border-gray-200 pt-2">
                <label htmlFor="intakeState" className="block text-sm font-medium text-gray-700">
                  {t('Intake state')}
                </label>
                <div className="pt-1">
                  <select
                    {...register('intakeState')}
                    id="intakeState"
                    name="intakeState"
                    autoComplete="intakeState-name"
                    className="block focus:ring-violet-500 focus:border-violet-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  >
                    {(
                      Object.keys(PrescriptionMedicineIntakeState) as Array<
                        keyof typeof PrescriptionMedicineIntakeState
                      >
                    ).map((value) => {
                      return (
                        <option key={value} value={value}>
                          {medicineIntakeState[value]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="flex justify-start space-x-2">
            <Button type="cancel" onClick={() => reset()}>
              {t('Clear')}
            </Button>
            <Button type="submit">{t('Add')}</Button>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};
MedicineSlideOverForm.defaultProps = {
  prescriptionId: null,
  defaultValues: undefined,
};

export default MedicineSlideOverForm;
