import moment from 'moment';
import { MessageType } from '.';
import { DayType } from './components/MessageList';

function groupMessages(messages: MessageType[]) {
  const groupedMessages: { [key: string]: MessageType[] } = {};
  messages.map((message) => {
    const messageDay = moment(message.created_at).format('YYYY-MM-DD');
    if (groupedMessages[messageDay]) {
      groupedMessages[messageDay].push(message);
    } else {
      groupedMessages[messageDay] = [message];
    }
    return groupedMessages;
  });
  return groupedMessages;
}

function generateItems(messages: MessageType[]) {
  const daysWithMessages = groupMessages(messages);
  const sortedDaysAsc = Object.keys(daysWithMessages).sort(
    (x, y) => moment(x, 'YYYY-MM-DD').unix() - moment(y, 'YYYY-MM-DD').unix()
  );

  const newMessages: (MessageType | DayType)[] = [];

  sortedDaysAsc.map((date) => {
    const sortedMessagesAsc = daysWithMessages[date];
    return newMessages.push({ type: 'day', date, id: date }, ...sortedMessagesAsc);
  });
  return newMessages;
}

export { generateItems };
