import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import PageNotFound from '../../components/PageNotFound';
import NoElementFound from '../../components/NoElementFound';
import Preloader from '../../components/Preloader';
import { BreadCrumbs, PaginationList } from '../../components';
import PatientSeizuresTable from '../../widgets/Seizure/PatientSeizuresTable';
import { IBreadCrumbPage } from '../../components/BreadCrumbs';
import { usePatientSeizuresQueryProvider } from '../../../providers';

const itemsOnPage = 10;

const PatientSeizuresPage = () => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParams] = useSearchParams();

  const patientId = searchParams.get('id');
  const [patientSeizures, { data, loading, error }] = usePatientSeizuresQueryProvider(
    patientId,
    itemsOnPage,
    itemsOnPage * (pageNumber - 1)
  );

  const pages: IBreadCrumbPage[] = [
    { name: t('Seizures'), href: '/seizures', current: false },
    {
      name: `${patientSeizures[0]?.user.firstName} ${patientSeizures[0]?.user.secondName}`,
      href: `/seizures/patient?id=${patientId}`,
      current: true,
    },
  ];

  const totalCount: number | undefined = data?.doctorSeizures.totalCount;

  if (loading) {
    return (
      <div className="h-[70vh] col-span-7 items-center flex justify-center">
        <Preloader />
      </div>
    );
  }

  if (!patientId) {
    return <NoElementFound title={t('Something went wrong')} />;
  }

  if (patientSeizures.length === 0) {
    return <NoElementFound title={t('No records found')} />;
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return <PageNotFound />;
  }

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">{t('Seizures')}</h1>
      <BreadCrumbs pages={pages} />
      <div className="pt-4">
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-1 md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                <PatientSeizuresTable patientSeizures={patientSeizures} />
                {totalCount && (
                  <PaginationList
                    itemsOnPage={itemsOnPage}
                    totalCount={totalCount}
                    selectedPage={pageNumber}
                    onPageSelect={setPageNumber}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientSeizuresPage;
