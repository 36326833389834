import React from 'react';
import doctorPlaceholder from '../../../assets/images/doctor-placeholder.png';

export interface Props {
  url?: string;
  className?: string;
  alt?: string;
}

const DoctorImage = ({ url, className, alt }: Props) => {
  return <img className={className} src={url} alt={alt} />;
};

DoctorImage.defaultProps = {
  url: doctorPlaceholder,
  alt: '',
  className: '',
};

export default DoctorImage;
