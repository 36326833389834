import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { usePatientOnlineStatus } from '../../../hooks/usePatientOnlineStatus';
import Image from '../../components/Image';

interface Props {
  id: string;
  imageUrl: string;
  name: string;
  userId: string;
}

const PatientCard = ({ id, imageUrl, name, userId }: Props) => {
  const { t } = useTranslation();
  const showOnline = usePatientOnlineStatus(userId);

  return (
    <Link
      to={id}
      className="mb-4 col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
    >
      <div className="flex-1 flex flex-col p-8">
        <Image url={imageUrl} className="w-32 h-32 flex-shrink-0 mx-auto rounded-full bg-slate-600" />
        <h3 className="mt-6 text-gray-900 text-sm font-medium">{name}</h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-gray-500 text-sm">{t('Your patient')}</dd>
          {/* <dt className="sr-only">Role</dt> */}
          <dd className="m-3">
            <span
              className={classNames(
                showOnline ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100',
                'px-4 py-1 text-xs font-medium rounded-full'
              )}
            >
              {showOnline ? t('Online') : t('Offline')}
            </span>
          </dd>
        </dl>
      </div>
    </Link>
  );
};

export default PatientCard;
