import { useCallback, useReducer } from 'react';
import { DoctorCreatePrescriptionMedicineInputType } from '../generated/graphql';
import { IMedicineFormData } from '../view/widgets/MedicineSlideOverForm';

interface MyPrescriptionType {
  description: string;
  listOfMedicines: DoctorCreatePrescriptionMedicineInputType[];
}

interface PayloadType {
  description?: string;
  medicine?: Partial<DoctorCreatePrescriptionMedicineInputType>;
  medicines?: DoctorCreatePrescriptionMedicineInputType[];
}

type ActionType = {
  type: 'ADD_MEDICINE' | 'CHANGE_DESCRIPTION' | 'SET_MEDICINES';
  payload: PayloadType;
};

const initialState: MyPrescriptionType = {
  description: '',
  listOfMedicines: [],
};

const dataChangeHandler = (state: MyPrescriptionType, action: ActionType): MyPrescriptionType => {
  const { description, medicine, medicines } = action.payload;

  switch (action.type) {
    case 'ADD_MEDICINE': {
      if (medicine) {
        return {
          ...state,
          listOfMedicines: [...state.listOfMedicines, medicine as DoctorCreatePrescriptionMedicineInputType],
        };
      }
      return state;
    }
    case 'SET_MEDICINES': {
      if (medicines && medicines.length > 0) {
        return {
          ...state,
          listOfMedicines: medicines,
        };
      }
      return state;
    }
    case 'CHANGE_DESCRIPTION': {
      return {
        ...state,
        description: description ?? '',
      };
    }
    default: {
      return state;
    }
  }
};

export const usePrescriptionState = (): [state: MyPrescriptionType, stateChangeActions: StateChangeActions] => {
  const [state, dispatch] = useReducer(dataChangeHandler, initialState);

  const addPrescriptionMedicine = useCallback(
    (medicine: Partial<DoctorCreatePrescriptionMedicineInputType>) => {
      dispatch({ type: 'ADD_MEDICINE', payload: { medicine } });
    },
    [dispatch]
  );
  const setPrescriptionMedicines = useCallback(
    (medicines: DoctorCreatePrescriptionMedicineInputType[]) => {
      dispatch({ type: 'SET_MEDICINES', payload: { medicines } });
    },
    [dispatch]
  );
  const editPrescriptionDescription = useCallback(
    (description: string) => {
      dispatch({ type: 'CHANGE_DESCRIPTION', payload: { description } });
    },
    [dispatch]
  );

  return [
    state,
    {
      addPrescriptionMedicine,
      editPrescriptionDescription,
      setPrescriptionMedicines,
    },
  ];
};

export interface StateChangeActions {
  addPrescriptionMedicine: (prescriptionMedicine: IMedicineFormData) => void;
  editPrescriptionDescription: (description: string) => void;
  setPrescriptionMedicines: (prescriptionMedicines: DoctorCreatePrescriptionMedicineInputType[]) => void;
}
