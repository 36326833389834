import React, { Fragment, useState } from 'react';
import { Combobox, Popover, Transition } from '@headlessui/react';
import classNames from 'classnames/bind';
import { ChatAltIcon, SearchIcon, XIcon } from '@heroicons/react/solid';
import { ChatAlt2Icon, UsersIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import Chat from '../..';
import ChatPatientsPerson from '../ChatPatientsPerson';

interface Person {
  id: string;
  name: string;
  imageUrl: string;
}

interface Props {
  people: Person[];
}

const ChatWidget = ({ people }: Props) => {
  const { t } = useTranslation();
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const [query, setQuery] = useState('');
  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Popover className="fixed top-[20vh] right-[300px] z-40 sm:z-10 md:right-[440px]">
      {({ open }) => (
        <>
          <Popover.Button>
            {open ? (
              <XIcon
                className="h-16 w-16 px-4 fixed right-8 bottom-8 bg-gray-300 hover:bg-gray-400 rounded-full"
                aria-hidden="true"
              />
            ) : (
              <ChatAltIcon
                className="h-16 w-16 px-3 fixed right-8 bottom-8 bg-gray-300 hover:bg-gray-400 rounded-full"
                aria-hidden="true"
              />
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="fixed pl-0 sm:right-8 right-0 sm:bottom-28 bottom-0 z-10 transform mt-3 w-screen max-w-screen sm:max-w-[400px]">
              <div
                className={classNames(
                  'grid bg-white rounded-0 sm:rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden h-screen sm:h-[500px]'
                )}
              >
                {/* Content start */}

                <Combobox
                  as="div"
                  className={classNames(
                    selectedPerson ? 'hidden' : '',
                    'transform divide-y flex flex-col divide-gray-100 overflow-hidden bg-white ring-1 ring-black ring-opacity-5 transition-all'
                  )}
                  value={selectedPerson}
                  onChange={setSelectedPerson}
                >
                  <>
                    <div className="flex flex-row">
                      <SearchIcon
                        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                        placeholder={t('Search...')}
                        onChange={(event) => setQuery(event.target.value)}
                      />
                      <Popover.Button className="sm:hidden">
                        <XIcon className="h-6 w-12 text-gray-500 hover:text-gray-700" aria-hidden="true" />
                      </Popover.Button>
                    </div>

                    {(query === '' || filteredPeople.length > 0) && (
                      <Combobox.Options as="div" static hold className="flex border-b-2 divide-x divide-gray-100">
                        <div className={classNames('max-h-96 min-w-0 flex-auto scroll-py-4 overflow-hidden')}>
                          <div className="-mx-2 px-6 text-sm text-gray-700">
                            {(query === '' ? people : filteredPeople).map((person) => (
                              <ChatPatientsPerson key={person.id} person={person} />
                            ))}
                          </div>
                        </div>
                      </Combobox.Options>
                    )}

                    {query !== '' && filteredPeople.length === 0 && (
                      <div className="flex flex-col justify-center flex-grow py-14 px-6 text-center text-sm sm:px-14">
                        <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                        <p className="mt-4 font-semibold text-gray-900">{t('No records found')}</p>
                        <p className="mt-2 text-gray-500">
                          {t(`You do not have patients with the given name. Please try again.`)}
                        </p>
                      </div>
                    )}
                  </>
                </Combobox>
                {/* Content end */}
                {/* Chat start */}
                <div
                  className={classNames(
                    !selectedPerson ? 'hidden' : '',
                    'col-span-1 flex flex-none flex-col divide-y divide-gray-100 overflow-y-auto'
                  )}
                >
                  {selectedPerson ? (
                    <Chat
                      goBack={() => {
                        setSelectedPerson(null);
                      }}
                      withUser={{
                        userId: selectedPerson.id,
                        name: selectedPerson.name,
                        avatar: { url: selectedPerson.imageUrl },
                      }}
                    />
                  ) : (
                    <div className="flex flex-col justify-center flex-grow py-12 px-6 text-center text-sm sm:px-14">
                      <ChatAlt2Icon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-2 text-gray-900">{t('No chat selected')}</p>
                    </div>
                  )}
                </div>
                {/* Chat start */}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ChatWidget;
