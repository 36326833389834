import { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { AuthContextType } from '../contexts/auth-context';

const SUBMIT_ONLINE_STATUS = gql`
  mutation ($userId: Int!) {
    update_core_user_by_pk(pk_columns: { id: $userId }, _set: { last_seen: "now()" }) {
      id
    }
  }
`;

function useDoctorOnlineMutation(authContext: AuthContextType) {
  const [onlineStatusMutation] = useMutation(SUBMIT_ONLINE_STATUS, {
    context: {
      clientName: 'chat',
    },
    variables: { userId: authContext.user?.id },
  });

  return useEffect(() => {
    let intervalId: number;
    if (authContext.user?.id) {
      intervalId = setInterval(onlineStatusMutation, 30000);
    }
    return () => clearInterval(intervalId);
  }, [authContext.user, onlineStatusMutation]);
}

export { useDoctorOnlineMutation };
