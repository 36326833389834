import React from 'react';
import noPatient from '../../../assets/images/no-patient.png';

interface Props {
  title: string;
}

const NoElementFound = ({ title }: Props) => {
  return (
    <div className="md:grid md:place-items-center lg:col-span-8">
      <div className="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <img
          className="h-32 w-32 xl:h-48 xl:w-48 inline-block object-cover bg-gray-100"
          src={noPatient}
          alt="noPatients"
        />
        <span className="mt-5 block text-sm xl:text-xl font-medium text-gray-900">{title}</span>
      </div>
    </div>
  );
};

export default NoElementFound;
