import React from 'react';
import moment from 'moment';
import { MessageType } from '../..';
import { useAuth } from '../../../../../contexts/auth-context';
import useChatMessageListProvider from '../../../../../providers/chat/useChatMessageListProvider';

const MessageItem = ({ message }: { message: MessageType }) => {
  const authContext = useAuth();

  const loggedInUserMessage = message.from_user_id === Number(authContext.user?.id);
  return (
    <li key={message.id} className={loggedInUserMessage ? 'flex justify-end' : 'flex justify-start'}>
      <div
        className={`flex flex-col max-w-full px-2 py-1 text-gray-700 ${
          !loggedInUserMessage ? 'bg-gray-100' : ''
        } rounded shadow`}
      >
        <span className="block break-words mr-2">{message.text}</span>
        <span className="text-xs text-slate-500 ml-2 self-end">
          {moment(new Date(message.created_at)).format('HH:mm')}
        </span>
      </div>
    </li>
  );
};

interface Props {
  messages: MessageType[];
}

export interface DayType {
  id?: string;
  date?: string;
  type?: string;
}

const MessageList = ({ messages }: Props) => {
  const [dayAgo, messagesEndRef] = useChatMessageListProvider(messages);
  return (
    <ul className="space-y-2">
      {messages.map((message: MessageType | DayType) => {
        if ('type' in message && message?.type && message?.type === 'day') {
          return (
            <div key={message.id} className="flex justify-center mb-2">
              <span className="inline-flex items-center px-2.5 py-1 rounded-full text-base font-medium bg-violet-100 text-gray-800">
                {dayAgo(message.date)}
              </span>
            </div>
          );
        }
        return <MessageItem key={message.id} message={message as MessageType} />;
      })}
      <div ref={messagesEndRef} />
    </ul>
  );
};

export default MessageList;
