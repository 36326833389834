import React from 'react';
import { ClipboardListIcon, HomeIcon, PresentationChartBarIcon } from '@heroicons/react/solid';

/* Styles */
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/tailwind.css';

import './styles/index.css';

/* Router stuff */
// import Router from "./routes/Router";
/* Translation */
import './i18n/i18n';

import { Navigate, Route, Routes } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import {
  PatientsPage,
  SeizuresPage,
  PatientSeizuresPage,
  ProfilePage,
  PatientPage,
  SignInPage,
  PrescriptionsPage,
  PrescriptionPage,
  NewPrescriptionPage,
  NewMedicinePage,
} from './view/pages';
import { useAuth } from './contexts/auth-context';
import SidebarLayout from './view/widgets/SidebarLayout';
import { useDoctorOnlineMutation } from './hooks/useDoctorOnlineMutation';

const navigation = [
  { name: 'Patients', href: '/patients', icon: HomeIcon },
  { name: 'Prescriptions', href: '/prescriptions', icon: ClipboardListIcon },
  { name: 'Seizures', href: '/seizures', icon: PresentationChartBarIcon },
];
const userNavigation = [{ name: 'View profile', href: 'profile' }];

const App = () => {
  const authContext = useAuth();
  useDoctorOnlineMutation(authContext);
  if (authContext.isFetchingFromStorage) {
    return null;
  }

  if (!authContext.registered) {
    return <SignInPage />;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<SidebarLayout userNavigation={userNavigation} navigation={navigation} />}>
          <Route index element={<Navigate to="patients" />} />
          <Route path="profile" element={<ProfilePage />} />
          {/* <Redirect index to={'patients'}/> */}
          <Route path="patients" element={<PatientsPage />} />
          <Route path="patients/:id" element={<PatientPage />} />
          <Route path="patients/:id/new-prescription" element={<NewPrescriptionPage />} />
          <Route path="seizures" element={<SeizuresPage />} />
          <Route path="seizures/patient" element={<PatientSeizuresPage />} />
          <Route path="prescriptions" element={<PrescriptionsPage />} />
          <Route path="prescriptions/:id" element={<PrescriptionPage />} />
          <Route path="new-medicine" element={<NewMedicinePage />} />
        </Route>
      </Routes>
    </>
  );
};

export { App };
