import React from 'react';
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrescriptionMedicine from '../PrescriptionMedicine';
import { Button } from '../../../components/Button';
import { DoctorCreatePrescriptionMedicineInputType } from '../../../../generated/graphql';

interface IPrescriptionFormProps {
  listOfMedicines: DoctorCreatePrescriptionMedicineInputType[];
  onSubmit: () => void;
  register: any;
  toggleMedicineSlideOver: () => void;
  title: string;
  defaultDescription?: string;
}

const PrescriptionForm = ({
  defaultDescription,
  listOfMedicines,
  onSubmit,
  register,
  toggleMedicineSlideOver,
  title,
}: IPrescriptionFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const addMedicineButton = (
    <button
      type="button"
      onClick={toggleMedicineSlideOver}
      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-emerald-700 bg-emerald-100 hover:bg-emerald-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
    >
      <PlusSmIconOutline className="h-4 w-4" aria-hidden="true" />
      <p>{t('Add medicine')}</p>
    </button>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div className="lg:col-span-9 xl:col-span-9 bg-white shadow sm:rounded-lg">
        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-8">
          <form onSubmit={onSubmit}>
            <div className="space-y-8 sm:space-y-12">
              <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">{t(title)}</h2>
              </div>
              <div className="text-left">
                <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-violet-600 focus-within:border-violet-600">
                  <label
                    htmlFor="name"
                    className="absolute -top-3 left-2 -mt-px inline-block px-1 bg-white text-m font-medium text-gray-900"
                  >
                    {t('Description')}
                  </label>
                  <textarea
                    {...register('description')}
                    name="description"
                    id="description"
                    autoComplete="description"
                    rows={4}
                    className="block w-full border-0 p-0 py-2 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                    defaultValue={defaultDescription}
                  />
                </div>
                {listOfMedicines.length > 0 ? (
                  <div className="my-4 relative">
                    {listOfMedicines.map((med) => (
                      <PrescriptionMedicine changeMedicine key={med.id} medicine={med} />
                    ))}
                    <div className="m-1">{addMedicineButton}</div>
                  </div>
                ) : (
                  <div className="my-4 flex-column justify-center">
                    <p className="mt-4 mb-2">{t('You have not added medicines to this prescription yet')}</p>
                    {addMedicineButton}

                    {/*  Toggle this if there are no medicines in array - start */}
                    {/*
                    <div className="rounded-md bg-red-50 p-4 mt-2">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            {t('Recipe cannot be created without medicine added')}
                          </h3>
                        </div>
                      </div>
                    </div>
                    */}
                    {/*  Toggle this if there are no medicines in array - end */}
                  </div>
                )}
                <div className="pt-5">
                  <div className="flex justify-end space-x-2">
                    <Button type="cancel" onClick={() => navigate(-1)}>
                      {t('Cancel')}
                    </Button>
                    <Button type="submit">{t('Save')}</Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
PrescriptionForm.defaultProps = {
  defaultDescription: '',
};

export default PrescriptionForm;
