import React from 'react';
import { Menu } from '@headlessui/react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Image from '../../../../components/Image';
import {
  DoctorPushNotificationsDocument,
  useRemoveDoctorPushNotificationMutation,
} from '../../../../../generated/graphql';

interface Props {
  id: string;
  patientFirstName: string;
  patientSecondName: string;
  patientImageUrl: string;
  text: string;
  createdTime: string;
}

const NotificationItem = ({ id, patientFirstName, patientSecondName, patientImageUrl, text, createdTime }: Props) => {
  const { t } = useTranslation();
  const notifyCreateDate = new Date(createdTime);
  const difference = moment(new Date()).diff(notifyCreateDate);
  const timeSinceNotification = moment.duration(difference, 'milliseconds').locale('en').humanize();

  const [removeDoctorPushNotificationMutation] = useRemoveDoctorPushNotificationMutation({
    refetchQueries: [DoctorPushNotificationsDocument],
  });

  const handleNotifyDelete = () => {
    removeDoctorPushNotificationMutation({
      variables: {
        input: {
          doctorNotifyId: id,
        },
      },
    });
  };

  return (
    <Menu.Item>
      {({ active }) => (
        <Link
          to="/#"
          onClick={handleNotifyDelete}
          className={classNames(
            active ? 'bg-gray-200' : '',
            'flex flex-row items-center px-4 py-2 text-sm bg-white text-gray-700'
          )}
        >
          <Image url={patientImageUrl} className="w-12 h-12 mr-2 mb-1 flex-shrink-0 rounded-full bg-slate-600" />
          <div className="flex flex-col">
            <div>{`${patientFirstName} ${patientSecondName} ${t(text)}`}</div>
            <div className="text-gray-400">{`${timeSinceNotification} ago`}</div>
          </div>
        </Link>
      )}
    </Menu.Item>
  );
};

export { NotificationItem };
