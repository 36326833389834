import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { CloudDownloadIcon } from '@heroicons/react/outline';
import {
  FileType,
  PatientDocument,
  useDoctorPatientFileDeleteMutation,
  useDoctorPatientFileUpdateMutation,
} from '../../../../../../generated/graphql';
import { Button } from '../../../../../components/Button';
import { errorToast, successToast } from '../../../../../../utils';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import Modal from '../../../../../components/Modal';
import { s3BucketService } from '../../../../../../services/S3Bucket.service';

interface IFileCardProps {
  children: React.ReactNode;
  file: FileType;
  patientFileId: string;
}

interface FormDefaultValuesType {
  title: string;
}

const FileCard = ({ patientFileId, file, children }: IFileCardProps) => {
  const { t } = useTranslation();
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const uploadFileInputRef = useRef<HTMLInputElement>(null);

  const { register, handleSubmit, setValue, formState } = useForm<FormDefaultValuesType>({
    mode: 'onChange',
    defaultValues: {
      title: '',
    },
  });
  const [removePatientFileMutation, { loading: removePatientFileMutationLoading }] = useDoctorPatientFileDeleteMutation(
    {
      refetchQueries: [PatientDocument],
      onError: () => {
        errorToast(t('File failed to delete'));
      },
      onCompleted: () => {
        successToast(t('File successfully deleted'));
        setIsOpenDeleteModal(false);
      },
    }
  );

  const [updatePatientFileMutation, { loading: updatePatientFileMutationLoading }] = useDoctorPatientFileUpdateMutation(
    {
      refetchQueries: [PatientDocument],
      onError: () => {
        errorToast(t('File failed to update'));
      },
      onCompleted: () => {
        successToast(t('File successfully updated'));
        setIsOpenUpdateModal(false);
      },
    }
  );

  const handleFileDelete = () => {
    removePatientFileMutation({
      variables: {
        input: {
          id: patientFileId,
        },
      },
    });
  };

  const handleFileUpdate = (data: FormDefaultValuesType) => {
    if (data.title) {
      updatePatientFileMutation({
        variables: {
          input: {
            id: patientFileId,
            title: data.title,
          },
        },
      });
    }
  };

  return (
    <div className="flex flex-row items-center bg-white rounded-xl border-violet-200 shadow overflow-hidden px-4 py-4 sm:rounded-md">
      <div className="">{children}</div>
      <div className="flex ml-auto space-x-2">
        <Button type="cancel" onClick={() => s3BucketService.downloadFileFromUrl(file.url, file.title)}>
          <div className="hidden sm:block mr-1">{t('Download')}</div>
          <CloudDownloadIcon className="h-5 w-5" />
        </Button>
        <Button
          onClick={() => {
            setIsOpenUpdateModal(true);
            setValue('title', file.title);
          }}
        >
          <div className="hidden sm:block mr-1">{t('Change')}</div>
          <PencilIcon className="h-5 w-5" />
        </Button>
        <Modal isOpen={isOpenUpdateModal} onCancel={() => setIsOpenUpdateModal(false)} title={t('Edit file name')}>
          <div className="flex flex-col mt-4">
            <input
              {...register('title', {
                required: 'Field is required.',
              })}
              type="text"
              id="title"
              name="title"
              className="mb-5 rounded-lg"
            />
            <div className="flex flex-row space-x-10">
              <Button className="w-full" type="cancel" onClick={() => setIsOpenUpdateModal(false)}>
                {t('Cancel')}
              </Button>
              <input type="file" id="file-upload" name="file-upload" className="hidden" ref={uploadFileInputRef} />
              <Button
                loading={updatePatientFileMutationLoading}
                className="w-full bg-violet-600 hover:bg-violet-800"
                disabled={!formState.isValid}
                onClick={() => {
                  handleSubmit(handleFileUpdate)();
                }}
              >
                <span>{t('Update')}</span>
              </Button>
            </div>
          </div>
        </Modal>
        <Button type="danger" onClick={() => setIsOpenDeleteModal(true)}>
          <div className="hidden sm:block mr-1"> {t('Delete')}</div>
          <TrashIcon className="h-5 w-5" />
        </Button>
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          onCancel={() => {
            setIsOpenDeleteModal(false);
          }}
          cancelText={t('Cancel')}
          onConfirm={handleFileDelete}
          confirmText={t('Delete')}
          confirmButtonType="danger"
          title={t('File delete confirmation')}
          loading={removePatientFileMutationLoading}
        >
          {t('Are you sure you want to delete the file?')}
        </ConfirmationModal>
      </div>
    </div>
  );
};

export default FileCard;
