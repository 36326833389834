import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames/bind';

interface IInputFieldProps {
  title: string;
  type: string;
  name?: string;
  className?: string;
  innerInputClassName?: string;
  placeholder?: string;
  error?: FieldError;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  defaultValue?: string;
}

const InputField = forwardRef<HTMLInputElement, IInputFieldProps>(
  (
    {
      name,
      title,
      type,
      innerInputClassName,
      className,
      onChange,
      error,
      defaultValue,
      disabled,
      onBlur,
      onKeyDown,
      placeholder,
    }: IInputFieldProps,
    ref
  ) => {
    return (
      <div className={classNames('border-t border-gray-200 pt-2', className)}>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {title}
        </label>
        <div className="pt-1">
          <input
            type={type}
            name={name}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={onChange}
            ref={ref}
            id={name}
            placeholder={placeholder}
            className={classNames(
              error
                ? `block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md`
                : `block w-full shadow-sm focus:ring-violet-500 focus:border-violet-500 sm:text-sm border-gray-300 rounded-md`,
              innerInputClassName
            )}
          />
        </div>
      </div>
    );
  }
);

InputField.defaultProps = {
  type: 'text',
  placeholder: undefined,
  error: undefined,
  onKeyDown: undefined,
  onChange: undefined,
  onBlur: undefined,
  name: undefined,
  title: undefined,
  className: '',
  innerInputClassName: '',
  disabled: false,
  defaultValue: undefined,
};

export default InputField;
