import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import PageNotFound from '../../components/PageNotFound';
import NoElementFound from '../../components/NoElementFound';
import Preloader from '../../components/Preloader';
import { useDoctorPrescriptionsQuery } from '../../../generated/graphql';
import PaginationList from '../../components/PaginationList';
import PrescriptionList from '../../widgets/Prescription/PrescriptionList';

const itemsOnPage = 10;

const PrescriptionsPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('patientId');
  const [pageNumber, setPageNumber] = useState(1);
  const [patientFilterId, setPatientFilterId] = useState(id);

  const { data, loading, error } = useDoctorPrescriptionsQuery({
    variables: {
      id: patientFilterId!,
      first: itemsOnPage,
      offset: itemsOnPage * (pageNumber - 1),
    },
  });

  const totalCount: number | undefined = data?.doctorPrescriptions.totalCount;

  if (loading) {
    return (
      <div className="h-[70vh] col-span-7 items-center flex justify-center">
        <Preloader />
      </div>
    );
  }

  if (data?.doctorPrescriptions.edges.length === 0) {
    return <NoElementFound title={t('Patients list is empty')} />;
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return <PageNotFound />;
  }

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">{t('Prescriptions')}</h1>
      <div className="p-0">
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-1 md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                <table className="lg:min-w-full min-w-[900px] divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {t('Data')}
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {t('Description')}
                      </th>
                      <th scope="col" className="flex px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <div className="pr-3">{t('Patient')}</div>
                        <button
                          className="font-normal rounded-full px-3 bg-violet-600 hover:bg-violet-800 focus:outline-none text-white"
                          onClick={() => setPatientFilterId('')}
                        >
                          {t('Show All')}
                        </button>
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4">
                        <span className="sr-only">{t('Edit')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y items-center divide-gray-200 bg-white">
                    {data && <PrescriptionList prescriptionQueryResult={data} />}
                  </tbody>
                </table>
                {totalCount && (
                  <PaginationList
                    itemsOnPage={itemsOnPage}
                    totalCount={totalCount}
                    selectedPage={pageNumber}
                    onPageSelect={setPageNumber}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionsPage;
