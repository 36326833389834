import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import Spinner from '../Spinner';

export interface IButtonProps {
  children: React.ReactNode;
  onClick?: (e: React.FormEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: 'cancel' | 'danger' | 'submit';
}

const Button = ({ children, onClick, className, disabled, loading, type }: IButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      type={type === 'submit' ? type : 'button'}
      onClick={onClick}
      className={classNames(
        `${className} h-10 leading-9 items-center text-sm flex 
        rounded-3xl justify-center py-2 px-4 border border-transparent 
        shadow-sm font-medium text-white focus:outline-none`,
        'disabled:opacity-30 disabled:pointer-events-none ',
        {
          'bg-violet-500 hover:bg-violet-600': type == null || type === 'submit',
          'bg-red-500 hover:opacity-75': type === 'danger',
          'border-violet-600 text-violet-600 bg-transparent hover:bg-violet-600/5': type === 'cancel',
        }
      )}
      disabled={disabled}
    >
      {loading ? (
        <>
          <Spinner
            classNames={classNames({
              'fill-red-500': type === 'danger',
              'fill-violet-600': type === 'cancel',
            })}
          />
          {t('Loading...')}
        </>
      ) : (
        children
      )}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  onClick: undefined,
  disabled: false,
  loading: false,
  type: undefined,
};

export { Button };
