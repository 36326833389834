import React from 'react';
import { DoctorPrescriptionsQuery } from '../../../../generated/graphql';
import PrescriptionItem from './components/PrescriptionItem';

interface IPrescriptionListProps {
  prescriptionQueryResult: DoctorPrescriptionsQuery;
}

const PrescriptionList = ({ prescriptionQueryResult }: IPrescriptionListProps) => {
  const prescriptions =
    prescriptionQueryResult.doctorPrescriptions.edges.map((edge, index) => ({
      id: edge.node.id,
      description: edge.node.description,
      patient: edge.node.patient,
      createdAt: edge.node.createdAt,
      firstName: edge.node.patient.user.firstName,
      secondName: edge.node.patient.user.secondName,
      avatarUrl: edge.node.patient.user.avatar?.url,
      isBackgroundGray: index % 2 === 0,
    })) || [];

  return (
    <>
      {prescriptions.map(({ id, description, ...args }) => (
        <PrescriptionItem key={id} prescriptionId={id} description={description} {...args} />
      ))}
    </>
  );
};

export default PrescriptionList;
