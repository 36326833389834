import React from 'react';
import { PatientType } from '../../../generated/graphql';
import SeizureCalendar from './components/SeizureCalendar';
import PatientFileList from '../PatientFile/PatientFileList';
import UploadPatientFile from '../PatientFile/UploadPatientFile';
import PatientInfo from './components/PatientInfo';
import PatientSeizureList from './components/PatientSeizureList';
import PatientPrescriptionsList from '../Prescription/PatientPrescriptionList';

interface Props {
  patient: PatientType;
}

const PatientDetail = ({ patient }: Props) => {
  return (
    <div className="mt-8 xl:grid xl:grid-cols-3 xl:grid-row-3 gap-4 space-y-5 xl:space-y-0">
      <div className="xl:col-span-2 xl:row-span-2">
        <PatientInfo patientInfo={patient} />
      </div>
      <div className="xl:col-span-1 xl:row-span-1">
        <SeizureCalendar data={patient.seizureSet} />
      </div>
      <div className="xl:col-span-1 xl:row-span-2">
        <PatientSeizureList patientSeizures={patient.seizureSet.edges.map((edge) => edge.node)} />
      </div>
      <div className="xl:col-span-2 xl:row-span-2">
        <div className="mb-4">
          <PatientFileList patientFiles={patient.patientfileSet.edges.map((wrappedFile) => wrappedFile.node)}>
            <UploadPatientFile patientId={patient.id} />
          </PatientFileList>
        </div>
        <PatientPrescriptionsList
          patientPrescriptions={patient.prescriptionSet.edges.map((prescription) => prescription.node)}
        />
      </div>
    </div>
  );
};

export default PatientDetail;
