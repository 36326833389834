import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import MedicineSlideOverForm, { IMedicineFormData } from '../../widgets/MedicineSlideOverForm';
import Preloader from '../../components/Preloader';
import PageNotFound from '../../components/PageNotFound';
import { usePrescriptionPageProvider } from '../../../providers';
import { PrescriptionType } from '../../../generated/graphql';
import PrescriptionForm from '../../widgets/Prescription/PrescriptionForm';

const PrescriptionPage = () => {
  const [isMedicineFormOpen, setIsMedicineFormOpen] = useState(false);
  const { id: prescriptionId } = useParams();
  const {
    PatientPrescriptionMedicines,
    PrescriptionDescription,
    createPrescriptionMedicine,
    doctorUpdatePrescriptionMutation,
    response: { loading },
  } = usePrescriptionPageProvider(prescriptionId);

  const addMedicineToForm = (medicine: IMedicineFormData) => {
    createPrescriptionMedicine(medicine);
    setIsMedicineFormOpen(false);
  };

  const { handleSubmit, register } = useForm({
    mode: 'onChange',
  });

  if (!prescriptionId) {
    return <PageNotFound />;
  }

  if (loading) {
    return (
      <div className="h-[70vh] col-span-7 items-center flex justify-center">
        <Preloader />
      </div>
    );
  }

  const handleUpdatePrescription = async ({ description }: PrescriptionType) => {
    // Проверка на изменение описания для отмены мутации при двойном срабатывании формы ( так как я создал форму в форме)
    if (description !== PrescriptionDescription) {
      await doctorUpdatePrescriptionMutation({
        variables: {
          input: {
            description,
            prescriptionId,
          },
        },
      });
    }
  };

  return (
    <>
      <MedicineSlideOverForm
        onSubmit={addMedicineToForm}
        onCancel={() => setIsMedicineFormOpen(false)}
        isOpen={isMedicineFormOpen}
        prescriptionId={prescriptionId}
      />
      <PrescriptionForm
        onSubmit={handleSubmit(handleUpdatePrescription)}
        title="Update prescription"
        listOfMedicines={PatientPrescriptionMedicines}
        toggleMedicineSlideOver={() => setIsMedicineFormOpen(true)}
        register={register}
        defaultDescription={PrescriptionDescription}
      />
    </>
  );
};

export default PrescriptionPage;
